import { ILoginResult } from '@infocity/mail-common/interfaces/loginResult';
import { cookieDelete, cookieSet } from '@infocity/mail-common/methods/cookies';

/**
 * Ustawienie cookie zalogowanego użytkownika
 *
 * @export
 * @param {(ILoginResult | null)} user
 */
export function setLoggedCookies(user: ILoginResult | null) {
  if (user == null) {
    cookieDelete('uid');
    cookieDelete('sid');
  } else {
    cookieSet('uid', user.UserID.toString());
    cookieSet('sid', user.SessionID);
  }
}

import { RouteRecordRaw } from 'vue-router';

import { checkLogged } from '@/methods/checkLogged';
import { viewRoute } from './view.route';

const boxComponent = () => import('@/views/Mail/Box.vue');
export const boxRoute: RouteRecordRaw = {
  name: 'box-root',
  path: '/box',
  redirect: { name: 'box', params: { folder: 0 } },
  component: () => import('@/components/main/EmptyRouter.vue'),
  beforeEnter: checkLogged,
  children: [
    {
      path: ':folder',
      name: 'box',
      component: boxComponent,

      props: route => {
        return {
          folder: route.params.folder,
          pageStart: +(route.query.start || 0),
          sst: route.query.sst,
          q: route.query.q
          // pageStart: +route.params.pageStart || 0
        };
      },

      // props: true,
      children: [viewRoute]
    },
    {
      path: 'create',
      name: 'mail:create',
      component: () => import('@/views/Mail/Box/Editor/MailEditor.vue'),
      props: route => {
        return {
          to: route.query.to
        }
      }
    }
  ]
};

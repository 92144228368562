import { RouteRecordRaw } from 'vue-router';

import { checkLogged } from '../../../methods/checkLogged';

export const settingsRoute: RouteRecordRaw = {
  name: 'settings',
  path: '/settings',
  component: () => import('../../../views/Settings/Settings.vue'),
  beforeEnter: checkLogged,
  redirect: { name: 'settings:mailfolders' },

  children: [
    {
      path: 'mailfolders',
      name: 'settings:mailfolders',

      props: route => {
        return { folder: route.query.folder };
      },
      component: () => import('../../../views/Settings/views/MailFolders.vue')
    },
    {
      path: 'mailsignature',
      name: 'settings:mailsignature',
      props: route => {
        return { sign: route.query.sign };
      },
      component: () => import('../../../views/Settings/views/MailSignature.vue')
    },
    {
      path: 'mailrules',
      name: 'settings:mailrules',

      props: route => {
        return { rule: route.query.rule };
      },
      component: () => import('../../../views/Settings/views/MailRules.vue')
    },
    {
      path: 'autoreply',
      name: 'settings:autoreply',
      /*
            props: route => {
              return { rule: route.query.rule };
            },
            */
      component: () => import('../../../views/Settings/views/AutoReply.vue')
    }
  ]
};

<template>
  <div class="notification">
    <div class="notification__mark">
      <w-icon v-if="type === NotificationType.ERROR" mdi icon="shield-alert" />

      <w-icon v-if="type === NotificationType.SUCCESS" mdi icon="check-circle" />
    </div>

    <div class="notification__content">
      <div class="notification__header">
        <p v-if="type === NotificationType.ERROR">Coś poszło nie tak</p>

        <p v-if="type === NotificationType.SUCCESS">Udało się</p>
      </div>

      <p class="notification__message">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { NotificationType } from '../../../enums/notificationType.enum';
/*
        <p v-if="type === NotificationType.ERROR">{{ $t('messages.notificationHeaderError') }}</p>

        <p v-if="type === NotificationType.SUCCESS">{{ $t('messages.notificationHeaderSuccess') }}</p>

*/

/**
 * Notyfikacja
 */
export default defineComponent({
  props: {
    /** Wiadomość do wyświetlenia */
    message: { type: String, required: true },

    //** Typ notyfikacji */
    type: { type: String }
  },

  setup() {
    return {
      NotificationType
    };
  }
});
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.toast {
  .notification {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
  }

  .notification__mark {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: radius('rounded');
    color: color('white');

    i {
      font-size: 24px;
    }
  }

  .notification__header {
    font-weight: weight('regular');
  }

  .notification__content {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    font-weight: weight('bold');
  }

  &.notification--success {
    border-color: color('success--bg');

    .toast__progress-bar {
      background-color: color('success--bg');
    }

    .notification__mark {
      background: color('success--bg');
    }

    .toast__content {
      background: rgba(color('success--bg'), 0.25);
    }
  }

  &.notification--error {
    border-color: color('warning--bg');

    .toast__progress-bar {
      background-color: color('warning--bg');
    }

    .notification__mark {
      background: color('warning--bg');
    }

    .toast__content {
      background: rgba(color('warning--bg'), 0.25);
    }
  }
}
</style>

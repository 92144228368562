import { RouteRecordRaw } from 'vue-router';

export const viewRoute: RouteRecordRaw = {
  path: 'view/:msgid/:msgf',
  name: 'mail:view',
  props: route => {
    return { msgid: parseInt(route.params.msgid as string), msgf: route.params.msgf };
  },
  component: () => import('@/views/Mail/Box/MailReader.vue')
};

<template>
  <teleport v-if="active" :to="active ? 'body' : undefined">
    <div class="modal-wrapper">
      <div class="modal-mask" @click="close"></div>

      <div class="modal">
        <div class="modal__header">
          <button class="btn btn--flat btn--icon btn--close-modal" @click="close">
            <w-icon mdi icon="close" />
          </button>

          <!-- Modal Header -->
          <slot name="header">
            <h3 class="m-0">
              {{ title }}
            </h3>
          </slot>
        </div>

        <div class="modal__content">
          <!-- Modal Content -->
          <slot />
        </div>

        <div v-if="$slots.footer" class="modal__footer">
          <!-- Modal Footer -->
          <slot name="footer" />
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { OnClickOutside } from '@vueuse/components';

export default defineComponent({
  components: { OnClickOutside },
  props: {
    active: {
      type: Boolean,
      default: false
    },

    title: {
      type: String
    }
  },
  emits: ['update:active', 'close'],
  setup(props, { emit }) {
    function close() {
      if (props.active) {
        // console.log('Modal:close');
        emit('close');
        emit('update:active', false);
      }
    }
    return { close };
  }
});
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-mask {
  background: rgba(color(dark), 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.modal {
  background: color(white);
  z-index: 10;
  width: 100%;
  max-width: 600px;
  animation: show-modal 0.3s ease;
  border-radius: radius(regular);
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &__content,
  &__header,
  &__footer {
    padding: 24px;
    position: relative;
  }

  &__content {
    flex: 1;
    overflow: auto;
  }

  &__footer {
    border-top: solid 1px color(border);
  }
  &__header {
    border-bottom: solid 1px color(border);
  }
}

.btn--close-modal {
  position: absolute;
  top: 8px;
  right: 8px;
}

@keyframes show-modal {
  0% {
    opacity: 0;
    transform: translateY(-32px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

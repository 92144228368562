<template>
  <div id="date-strap" class="date-strap">
    <div class="flex-col justify-center">
      <p class="text-uppercase">{{ day }}</p>

      <p class="weight-bold">{{ date }}</p>
    </div>

    <div class="flex-col ml-4">
      <div class="d-flex align-center">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import currentDay, { CurrentDay } from '@infocity/mail-common/methods/currentDay';
import settings from '@infocity/mail-common/services/serviceSettings';
import { defineComponent, ref, watch } from 'vue';

// Zakładka nad listą wiadomości
export default defineComponent({
  setup() {
    if (!currentDay.autoRefresh) {
      currentDay.autoRefresh = true;
    }
    const today = ref<CurrentDay>(currentDay);
    const day = ref<string>();
    const date = ref<string>();

    // ustawienie daty
    function setDate() {
      day.value = settings.lang.dayOfWeekFull[today.value.currentWeekDay];
      date.value =
        today.value.currentDay.toString().padStart(2, '0') +
        '.' +
        (today.value.currentMonth + 1).toString().padStart(2, '0');
    }

    watch(
      () => today.value.currentDay,
      () => setDate()
    );

    setDate();

    return { date, day };
  }
});
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.date-strap {
  background-color: map-get($map: $theme-colors, $key: white);
  display: flex;
  height: 100%;
  padding: 8px;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e1e1e1' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");

  @media screen and (max-width: map-get($grid-breakpoints, lg)) {
    display: none;
  }

  p {
    margin: 0;
    font-family: 'Nunito', sans-serif;
  }
}
</style>

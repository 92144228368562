<template>
  <button class="btn btn--cta" :class="{ 'btn--cta--outline': outlined }" @click="$emit('click', $event)">
    {{ text }}

    <div class="btn-icon">
      <w-icon mdi :icon="icon" />
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// Przycisk call-to-action
export default defineComponent({
  props: {
    // Tekst w przycisku
    text: {
      type: String
    },
    // Ikona w przycisku
    icon: {
      type: String,
      default: 'arrow-right'
    },
    // Przycisk ze stylami outlined
    outlined: {
      type: Boolean
    }
  },
  emits: ['click']
});
</script>

const lang = {
  code: 'pl',

  dayOfWeek: ['N', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'N'],
  dayOfWeekFull: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'],

  folders: ['Skrzynka odbiorcza', 'Wysłane', 'Robocze', 'Kosz', 'S P A M'],

  colorsSet: [
    'czarnym',
    'zielonym',
    'bordowym',
    'granatowym',
    'fioletowym',
    'czerwonym',
    'niebieskim',
    'szarym',
    'turkusowym'
  ],

  settings: {
    menutext: 'ustawienia',
    items: {
      accounts: {
        menutext: 'konto',
        items: {
          account: 'Twoje konto',
          aliases: 'aliasy',
          custom: 'ustawienia ogólne',
          stats: 'statystyki'
        }
      },
      // receiving: {
      //    menutext: "czytanie wiadomości",
      //    items: {
      //        list: "lista wiadomości",
      //        view: "pojedyncza wiadomość"
      //    }
      // },
      // sending: {
      //    menutext: "wysyłanie wiadomości",
      //    items: {
      //        form: "formularz wysyłania"
      //    }
      // },
      signatures: {
        menutext: 'podpisy',
        default: 'domyślny',
        settings: 'ustawienia podpisu',
        new: 'nowy',
        name: 'nazwa',
        auto: 'domyśny',
        text: 'podpis tekstowy',
        html: 'podpis html',
        alerts: {
          deleteConfirm: 'Na pewno chcesz usunąć podpis?',
          deleteOk: 'Podpis został usunięty',
          deleteErr: 'Podpis NIE został usunięty',
          saveOk: 'Podpis został zapisany',
          saveErr: 'Podpis NIE został zapisany'
        }
      },
      folders: {
        menutext: 'foldery'
      },
      filters: {
        menutext: 'filtry',
        items: {
          whitelist: 'akceptowani nadawcy',
          blacklist: 'blokowani nadawcy',
          ruleset: 'reguły wiadomości'
        }
      },
      labels: {
        menutext: 'etykiety'
      },
      remote: {
        menutext: 'konta zewnętrzne'
      }
    },
    forms: {
      accounts: {
        account: {
          account: 'konto',
          email: 'e-mail',
          sendingName: 'podpis',
          password: 'hasło',
          changePasswordLnk: 'zmień hasło',
          forward: 'odebrane prześlij na adres',
          changePassword: {
            title: 'Zmień hasło',
            oldPassword: 'aktualne hasło',
            newPassword: 'nowe hasło',
            newPassword2: 'powtórz nowe hasło',
            errors: {
              toShort: 'Nowe hasło nie może być krótsze niż 6 znaków',
              notEqual: 'Nowe oraz powtórzone nowe hasła muszą być takie same',
              empty: 'Nowe hasło nie może być puste',
              toolong: 'Nowe hasło nie może być dłuższe niż 15 znaków',
              badpassword: 'Niepoprawne aktualne hasło'
            },
            success: 'Hasło zostało zmienione'
          }
        }
      },
      aliases: {
        forminfo:
          'Dzięki aliasom możesz do swojego konta przypisać dodatkowe adresy np. dla kontaktów firmowych czy prywatnych.',
        listinfo: 'lista aliasów',
        limitReached: 'maksymalna liczba aliasów wynosi: {0}',
        form: {
          title: 'Alias',
          name: 'nazwa',
          domain: 'domena',
          alias: 'alias'
        }
      },
      custom: {
        incoming: 'wiadomości przychodzące',
        Forward: 'prześlij dalej na konto',
        SpamAction: 'wiadomości SPAM',
        SpamActions: {
          action0: 'dopisz [SPAM] i umieść w folderze SPAM',
          action1: 'dopisz [SPAM] i umieść w koszu (niedostępne przez POP3)',
          action2: 'dopisz [SPAM]',
          action3: 'automatycznie usuwaj (niedostępne przez POP3)',
          action4: 'nic nie rób'
        },
        SpamBayesian: 'dodatkowy filtr treści wiadomości',
        outgoing: 'wiadomości wychodzące',
        AutoSaveMail: "po wysłaniu zapisz w folderze 'Wysłane'",
        ConfirmMode: 'żadaj potwierdzenia',
        SmtpSaveOutbox: 'zapisuj wysłane przez SMTP'
      },
      stats: {
        account: 'konto',
        UserName: 'nazwa konta',
        CreateDate: 'data założenia konta',
        CreateIP: 'IP założenia konta',
        ValidTill: 'data ważności konta',
        FolderSize: 'pojemność skrzynki',
        MailFolderSize: '',
        FileFolderSize: '',
        FotoFolderSize: '',
        WWWFolderSize: '',
        TotalSize: 'zajmowane',
        www: 'www',
        LastLogin: 'ostatnie logowanie',
        LastLoginIP: 'adres ostatniego logowania',
        VisitsCount: 'ilość logowań',
        pop3: 'pop3',
        POP3LastLogin: 'ostatnie logowanie',
        POP3LastIP: 'adres ostatniego logowania',
        POP3RetrCount: 'ilość odebranych wiadomości',
        smtp: 'smtp',
        SMTPLastSent: 'ostatnie logowanie',
        SMTPLastSentIP: 'adres ostatniego logowania',
        SMTPTotalCount: 'ilość wysłanych wiadomości',
        imap: 'imap',
        IMAPLastLogin: 'ostatnie logowanie',
        IMAPLastIP: 'adres ostatniego logowania',
        IMAPLoginCount: 'ilość połączeń'
      },
      whitelist: {
        addtitle: 'dodaj adres',
        addaddress: 'podaj nowy adres lub domenę',
        listtitle: 'lista akceptowanych nadawców'
      },
      blacklist: {
        addtitle: 'dodaj adres',
        addaddress: 'podaj nowy adres lub domenę',
        listtitle: 'lista zablokowanych nadawców'
      },
      folders: {
        title: 'foldery poczty'
      },
      labels: {
        new: 'nowa',
        title: 'etykieta',
        name: 'nazwa',
        email: 'adres/adresy',
        success: 'etykieta została zapisana',
        error: 'podczas zpisywania etykiety wystąpił błąd',
        confirm: 'Na pewno chcesz usunąć etykietę?'
      },
      ruleset: {
        title: 'reguły wiadomości',
        listFormat: 'Jeśli {0} {1} {2}', // Jeśli {warunek} {wartość} {akcja}
        fields: [
          'od',
          'do',
          'temat',
          'treść',
          'adres odpowiedzi',
          'rozmiar jest większy niż',
          'wiadomość posiada załączniki',
          'wiadomość przyszła na konto',
          'wszystkie wiadomości',
          'pole nagłówka wiadomości',
          'liczba załączników wiadomości',
          'nazwa załącznika'
        ],
        fieldsSelect: {
          From: 'pole od',
          To: 'pole do',
          Subject: 'pole temat',
          TextBody: 'tekstowa treść wiadomości',
          ReplyTo: 'adres odpowiedzi',
          SizeMoreThanKb: 'rozmiar wiadomości',
          HasAttachments: 'wiadomość posiada załączniki',
          AccountId: 'wiadomość przyszła na konto',
          AllMessages: 'wszystkie wiadomości',
          OtherHeaderField: 'pole nagłówka wiadomości',
          AttachmentsCount: 'liczba załączników wiadomości',
          AttachmentFileName: 'nazwa załącznika'
        },
        condition: 'jeżeli',
        action: 'wtedy',
        contains: 'zawiera',
        isBigger: 'jest większy niż',
        isEqual: 'jest równa',
        textToSearch: 'szukany tekst',
        sizeOfMessage: 'rozmiar wiadomości',
        headerName: 'nazwa pola w nagłówku',
        headerValue: 'wartość pola w nagłówku',
        numberOfAtt: 'liczba załączników',
        forwardTo: "adresy e-mail oddzielone ','",
        autoreply: 'treść odpowiedzi',
        actions: [
          'przenieś do folderu',
          'oznacz jako przeczytane',
          'wyróżnij kolorem',
          'skasuj',
          'usuń z serwera POP3',
          'prześlij dalej na konto',
          'odpowiedz'
        ],
        actionsTitle: [
          'przenosi wiadomość do wskazanego folderu',
          '',
          'pozwala wyróżnić wiadomość kolorem na liście wiadomości',
          'wiadomość jest kasowana bezpowrotnie',
          'wiadomość jest przenoszona do folderu Kosz dzięki czemu nie można jej pobrać przez POP3',
          'umożliwia przesłanie wiadomości na inne adresy e-mail, kilka adresów należy oddzielić przecinkiem',
          'autoodpowiedź na wybrane adresy'
        ],
        additional: 'dodatkowo'
      },
      remote: {
        title: 'konta zewnętrzne',
        account: 'konto',
        Boxname: 'przyjazna nazwa',
        AccountEmail: 'adres e-mail',
        AccountName: 'podpis',
        remotedownload: 'pobieranie wiadomości',
        remotesend: 'wysyłanie wiadomości',
        usesend: 'wysyłaj wiadomości jako inny użytkownik',
        usesendinfo: 'na podany adres e-mail zostanie wysłana wiadomość z kodem umożliwiającym weryfikację',
        entercode: 'podaj kod weryfikacyjny z otrzymanej wiadomości',
        Pop3Server: 'adres serwera',
        Pop3Port: 'numer portu',
        Pop3User: 'nazwa użytkownika',
        Pop3Password: 'hasło',
        DelMsg: 'usuwaj wiadomości z serwera po odebraniu',
        additional: 'dodatkowe',
        Color: 'wyróżnij wiadomości kolorem',
        saveOk: 'konto zostało zapisane',
        saveErr: 'konto NIE zostało zapisane',
        delTitle: 'usuwanie konta',
        delBody: 'Na pewno chcesz usunąć konto <b>{0}</b>',
        delOk: 'konto zostało usunięte',
        delErr: 'konto NIE zostało usunięte'
      }
    }
  }
};

export default lang;

import { Mail } from '@infocity/mail-common/models/mail';
import { Connection, Proxy, hubConnection } from 'signalr-no-jquery';

import { BaseService } from '@infocity/mail-common/services/base.service';
import { ServiceSettings } from '@infocity/mail-common/services/serviceSettings';
import { MailSearchOptions } from '../models/mailSearchOptions';

export enum SearchStatus {
  Started = 'started',
  Cancelled = 'cancelled',
  Finished = 'finished',
  Searching = 'searching'
}

export class SignalRService extends BaseService {
  conn: Connection | null;
  notifyHub?: Proxy;
  searchHub?: Proxy;
  connectionId?: string;
  onFound?: (searchId: string, msg: Mail) => void;
  onSearchStatus?: (searchId: string, status: SearchStatus, statusData: string) => void;
  onNewMessage?: (fileIndex: number, subject: string, fromName: string, fromAddress: string, folder: number) => void;

  constructor(settings?: ServiceSettings) {
    super(settings);
    this.conn = null;
  }
  open(url?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // debugger;
      if (url === undefined) {
        url = this.settings.signalRUrl;
      }
      if (this.conn) {
        this.close();
      }
      this.conn = hubConnection(url);
      // (this.conn as any).clientProtocol = '1.5';
      this.notifyHub = this.conn.createHubProxy('NotifyHub');
      this.searchHub = this.conn.createHubProxy('SearchHub');

      //events
      this.searchHub?.on('foundMessage', (searchId: string, msgarray: any[]) => {
        if (this.onFound) {
          this.onFound(searchId, new Mail(msgarray));
        }
      });
      this.searchHub?.on('searchStatus', (searchId: string, status: string, statusData: string) => {
        if (this.onSearchStatus) {
          this.onSearchStatus(searchId, status as SearchStatus, statusData);
        }
      });
      this.notifyHub?.on(
        'newMessage',
        (fileIndex: number, subject: string, fromName: string, fromAddress: string, folder: number) => {
          if (this.onNewMessage) {
            this.onNewMessage(fileIndex, subject, fromName, fromAddress, folder);
          }
        }
      );

      this.conn.start().done(() => {
        resolve(true);
      });
    });
  }
  start(account: string, sid: string, uid: number): Promise<string> {
    //połacz jeśli nie jest połaczone
    if (!this.conn) {
      return this.open().then(connected => {
        if (connected) {
          return this.start(account, sid, uid);
        } else {
          return Promise.resolve('');
        }
      });
    }

    if (!this.notifyHub) {
      return Promise.resolve('');
    }

    return this.notifyHub.invoke('Start', account, sid, uid).then((connectionId: string) => {
      this.connectionId = connectionId;
      return connectionId;
    });
  }
  currentUser(): Promise<string> {
    if (!this.searchHub) {
      return Promise.resolve('');
    }
    return this.searchHub.invoke('CurrentUser').then((user: string) => {
      return user;
    });
  }

  startSearch(q: string, folder: number = -1, rparams?: { s?: number; ps?: number }): Promise<string> {
    if (!this.searchHub) {
      return Promise.resolve('');
    }
    return this.searchHub.invoke('SimpleSearch', q, folder, rparams).then((searchId: string) => {
      return searchId;
    });
  }
  startFullSearch(
    params: MailSearchOptions,
    folder: number = -1,
    rparams?: { s?: number; ps?: number }
  ): Promise<string> {
    if (!this.searchHub) {
      return Promise.resolve('');
    }
    return this.searchHub.invoke('FullSearch', params, folder, rparams).then((searchId: string) => {
      return searchId;
    });
  }
  cancelSearch(searchId: string): Promise<boolean> {
    if (!this.searchHub) {
      return Promise.resolve(false);
    }
    return this.searchHub.invoke('Cancel', searchId).then((cancelled: boolean) => {
      return cancelled;
    });
  }

  close() {
    if (this.conn) {
      this.conn.stop();
      this.conn = null;
    }
    /*
        if (this.websocket) {
            this.websocket.close();
            this.websocket = null;
        }
        if (this.pingTimer) {
            clearInterval(this.pingTimer);
            this.pingTimer = 0;
        }
        */
  }
}

export const signalRService = new SignalRService();
export default signalRService;

import { IStatusResult } from '@infocity/mail-common/interfaces/statusResult';
import { MailFolder } from '@infocity/mail-common/models/mailFolder';
import { BaseService } from '@infocity/mail-common/services/base.service';
import { ServiceSettings } from '@infocity/mail-common/services/serviceSettings';

export class MailFolderService extends BaseService {
  constructor(settings?: ServiceSettings) {
    super(settings);
  }
  /**
   * pobranie listy folderów
   */
  getFolders(): Promise<MailFolder[]> {
    return this.getJson<any>('Folders/Index').then(res => MailFolder.fromList(res.list));
  }
  /**
   * Zapis zmian we folderze
   * @param id id folderu
   * @param name nazwa folderu
   * @param parent folder nadrzędny
   */
  save(id: null | number, name: string, parent?: number): Promise<IStatusResult & { folder?: MailFolder }> {
    return this.postJson<any>('Folders/Save', { id, name, parent }).then(res => {
      if (res.Status === 'Ok' && (res as any).folder) {
        (res as any).folder = new MailFolder((res as any).folder);
      }
      return res;
    });
  }
  /**
   * Usunięcie folderu
   * @param id id folderu
   */
  delete(id: number): Promise<IStatusResult> {
    return this.postJson('Folders/Delete', { id });
  }
}

export const mailFolderService = new MailFolderService();
export default mailFolderService;

<template>
  <div ref="target" class="w-dropdown-target" :class="{ 'w-dropdown-target--block': block }">
    <!-- @slot Miejsce do wstawienia dropdown (do wyliczenia pozycji)-->
    <slot name="target" />

    <teleport v-if="isOpen" :to="isOpen ? 'body' : undefined">
      <on-click-outside
        ref="dropdown"
        class="w-dropdown-wrapper"
        :class="orientationClass"
        :style="position"
        @trigger="$emit('click-outside')"
        @mouseleave="$emit('mouse-leave')"
      >
        <div class="w-dropdown" :class="dropdownClass">
          <!-- @slot Zawartość dropdown-->
          <slot />
        </div>
      </on-click-outside>
    </teleport>
  </div>
</template>

<script lang="ts">
import { OnClickOutside } from '@vueuse/components';
import { useDebounceFn } from '@vueuse/core';
import { defineComponent, ref, watch, onBeforeUnmount } from 'vue';

import { usePosition } from '../../composables/position';

/** Select uniwersalny */
export default defineComponent({
  components: { OnClickOutside },
  props: {
    /** Czy dropdown jest otwarty */
    isOpen: { type: Boolean, require: true, default: false },

    /** Klasa nadawana na dropdown */
    dropdownClass: { type: String, require: false },

    /** Rozciągaj dropdown do pełnej szerokości jego parenta */
    block: { type: Boolean, required: false }
  },
  emits: {
    /** Kliknięcie poza elementem */
    'click-outside': null,
    /** Wysunięcie myszki poza element */
    'mouse-leave': null
  },

  setup(props) {
    const { target, position, orientationClass, setPosition } = usePosition({
      targetMargin: 300,
      defaultYPosition: 'bottom',
      defaultXPosition: 'left',
      orientationClassPrefix: 'w-dropdown--'
    });

    /**
     * Element HTML dropdowna
     */
    const dropdown = ref();

    const debouncedSetPosition = useDebounceFn(() => setPosition(), 50);
    watch(
      () => props.isOpen,
      isOpen => {
        if (isOpen) {
          setPosition();
          window.addEventListener('scroll', debouncedSetPosition);
          return;
        }
        window.removeEventListener('scroll', debouncedSetPosition);
      }
    );

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', debouncedSetPosition);
    });

    return { dropdown, position, orientationClass, target };
  }
});
</script>

<styles lang="scss">
@import '@/scss/_variables.scss';

.w-dropdown-target {
  position: relative;
  width: fit-content;

  &--block {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.w-dropdown-wrapper {
  position: absolute;
  padding: space('dropdown-distance');
  display: block;
  z-index: 10;
  margin: 0;
  top: 0;
  left: 0;
}

.w-dropdown {
  background: map-get($map: $theme-colors, $key: white);
  border-radius: map-get($map: $theme-radius, $key: regular);
  box-shadow: map-get($map: $theme-shadows, $key: large);
  border: solid 1px map-get($map: $theme-colors, $key: border);
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 6;
  width: 100vh;
  max-width: 300px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;

  // top
  &--top {
    animation: object-from-bottom 0.3s ease;
    animation-fill-mode: forwards;
  }

  // bottom
  &--bottom {
    animation: object-from-top 0.3s ease;
    animation-fill-mode: forwards;
  }
}

@keyframes object-from-top {
  0% {
    opacity: 0;
    top: -8px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes object-from-bottom {
  0% {
    opacity: 0;
    top: 8px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}
</styles>

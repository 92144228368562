import { RouteRecordRaw } from 'vue-router';

import { checkLogged } from '@/methods/checkLogged';
import { viewRoute } from './view.route';


export const todayRoute: RouteRecordRaw = {
  name: 'today',
  path: '/today',
  component: () => import('@/views/Mail/Box.vue'),
  props: route => {
    return {
      pageStart: +(route.query.start || 0),
      sst: route.query.sst,
      q: route.query.q
      // pageStart: +route.params.pageStart || 0
    };
  },

  beforeEnter: checkLogged,
  children: [{ ...viewRoute, name: 'today:view' }]
};

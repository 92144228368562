<template>
  <i :aria-hidden="!label ? 'true' : undefined" :aria-label="!!label ? label : undefined" :class="iconClass" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

// Ikona
export default defineComponent({
  name: 'w-icon',
  props: {
    // MaterialDesignIcons
    mdi: { type: Boolean },
    // FontAwesome
    fa: { type: Boolean },
    // FontAwesome LIGHT
    fal: { type: Boolean },
    // FontAwesome REGULAR
    far: { type: Boolean },
    // FontAwesome SOLID
    fas: { type: Boolean },
    // WitkacDesignIcons
    wdi: { type: Boolean },
    // nazwa ikony bez przedrostka
    icon: { type: String },
    // Label dodawany do ikonki (wyłącza `aria-hidden`)
    label: { type: String }
  },
  setup(props) {
    const iconClass = computed(() => {
      if (props.mdi) {
        return prepareIcons(props.icon, 'mdi');
      } else if (props.fa) {
        return prepareIcons(props.icon, 'fa');
      } else if (props.fal) {
        return prepareIcons(props.icon, 'fal', 'fa');
      } else if (props.far) {
        return prepareIcons(props.icon, 'far', 'fa');
      } else if (props.fas) {
        return prepareIcons(props.icon, 'fas', 'fa');
      } else if (props.wdi) {
        return prepareIcons(props.icon, 'wdi');
      }
    });

    return {
      iconClass
    };
  }
});

/**
 * Przygotowanie styli css
 *
 * @param {string} [icon]
 * @param {string} [iconset]
 * @param {string} [prefix]
 * @returns {string}
 */
function prepareIcons(icon?: string, iconset?: string, prefix?: string): string {
  const iconArr = [iconset];
  const iconPrefix = (prefix || iconset) + '-';
  if (icon) {
    iconArr.push(
      ...icon
        .split(' ')
        .filter(Boolean)
        .map(icon => (icon.startsWith(iconPrefix) ? icon : iconPrefix + icon))
    );
  }
  return iconArr.join(' ');
}
</script>

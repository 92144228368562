import { NotificationType } from '../enums/notificationType.enum';
import Notification from '../components/toast/toasts/Notification.vue';
//import { useTranslation } from '/composables/useTranslation';
//import { Errors } from '@/enums/errors.enum';
import { NotificationOptions } from '../interfaces/notificationOptions';
import { ToastOptions } from '../interfaces/toastOptions';

import { toastService } from './toast.service';

/**
 * Serwis notyfikacji
 *
 * @export
 * @class NotificationService
 */
export class NotificationService {
  public defaultMiliseconds: number = 2000;
  /**
   * Funkcja tłumacząca wiadomości
   *
   * @private
   * @memberof NotificationService
   */
  // private readonly translate: (key: string, choice?: number | Record<string, string>) => string;

  constructor() {
    // this.translate = useTranslation().translate;
  }

  /**
   * Notyfikacja o błędzie z API
   *
   * @param {{ response?: { data?: { code?: string } } }} error
   * @param {NotificationOptions} [options={}]
   * @memberof NotificationService
   */
  public notifyApiError(error: { response?: { data?: { code?: string } } }, options: NotificationOptions = {}): void {
    // this.notifyError(error.response?.data?.code || Errors.GENERIC_API_ERROR, options);
  }

  /**
   * Notyfikacja o błędzie
   *
   * @param {string} message
   * @param {NotificationOptions} [options={}]
   * @memberof NotificationService
   */
  public notifyError(message: string, options: NotificationOptions = {}): void {
    this.notify(NotificationType.ERROR, 'errors.' + message, options);
  }

  /**
   * Notyfikacja o sukcesie
   *
   * @param {string} message
   * @param {NotificationOptions} [options={}]
   * @memberof NotificationService
   */
  public notifySuccess(message: string, options: NotificationOptions = {}): void {
    this.notify(NotificationType.SUCCESS, message, options);
  }

  /**
   * Notyfikacja
   *
   * @private
   * @param {NotificationType} type
   * @param {string} message
   * @param {NotificationOptions} [options={}]
   * @memberof NotificationService
   */
  private notify(type: NotificationType, message: string, options: NotificationOptions = {}): void {
    const toast: ToastOptions = {
      // props: { message: this.translate(message), type },
      props: { message: message, type },
      miliseconds: options.miliseconds == undefined ? this.defaultMiliseconds : options.miliseconds,
      component: Notification,
      cssClassess: `notification--${type} ${options.cssClassess || ''}`
    };

    toastService.create(toast);
  }
}

export const notificationService = new NotificationService();

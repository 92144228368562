
<template>
  <div v-if="toasts.length" class="toast-container">
    <toast v-for="toast of toasts" :key="'toast_' + toast.id" :toast="toast" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { toastService } from '../../services/toast.service';

import Toast from './Toast.vue';

/**
 * Kontener notyfikacji
 */
export default defineComponent({
  components: { Toast },
  setup() {
    return { toasts: toastService.toasts };
  }
});
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.toast-container {
  position: fixed;
  right: 0;
  top: 0;
  padding: 16px;
  z-index: 99;
  width: 100%;
  max-width: size('toast-w');
  max-height: 100vh;
  overflow-y: auto;
}
</style>

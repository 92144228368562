<template>
  <div class="app-content" :class="{ 'app-before-login': !isLoggedIn }">
    <div class="router-content">
      <router-view />
    </div>
  </div>
  <toast-container />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ToastContainer from '@/components/toast/ToastContainer.vue';
import { notificationService } from './services/notification.service';
import { signalRService } from './services/signalR.service';

import { isLoggedIn } from './store';

export default defineComponent({
  name: 'App',
  components: { ToastContainer },

  setup() {
    // podłącznie się do signalR
    signalRService.onNewMessage = (
      fileIndex: number,
      subject: string,
      fromName: string,
      fromAddress: string,
      folder: number
    ) => {
      notificationService.notifySuccess(subject, { miliseconds: 5000 });
    };

    return { isLoggedIn };
  }
});
</script>

<style lang="scss">
@import '@/scss/global.scss';
@import '@mdi/font/css/materialdesignicons.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .app-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  .router-content {
    display: flex;
    padding-top: 0;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
}

.app-before-login {
  background-color: map-get($map: $theme-colors, $key: dark);

  .router-content {
    padding: 0 !important;
  }
}
</style>

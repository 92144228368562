<template>
  <div class="app-aside">
    <logo-site />

    <div class="app-aside--router-content">
      <slot />
    </div>

    <section>
      <h3>Tryb wyświetlania</h3>
    </section>

    <contact-info />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContactInfo from '../ContactInfo.vue';

//Główne-Lewe menu aplikacji
export default defineComponent({
  components: {
    ContactInfo
  }
});
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.app-aside {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: map-get($map: $theme-width, $key: aside);
  flex-basis: map-get($map: $theme-width, $key: aside);
  padding-right: 60px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 24px;

  @media screen and (max-width: map-get($grid-breakpoints, xl)) {
    width: width(aside-xl);
    flex-basis: width(aside-xl);
  }

  @media screen and (max-width: map-get($grid-breakpoints, lg)) {
    width: width(aside-lg);
    flex-basis: width(aside-lg);
  }

  @media screen and (max-width: map-get($grid-breakpoints, md)) {
    width: width(aside-md);
    flex-basis: width(aside-md);
  }

  .logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .app-aside--router-content {
    padding-bottom: 40px;
  }

  section {
    border-top: solid 1px map-get($map: $theme-colors, $key: border);
    padding: 24px 0;
  }
}
</style>

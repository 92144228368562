<template>
  <li class="folder-item">
    <router-link :to="{ name: 'box', params: { folder: folder.index } }" :class="classess">
      {{ displayName }}

      <span v-if="folder.unread">&nbsp;({{ folder.unread }})</span>
    </router-link>

    <ul v-if="opened" class="folder-list--child">
      <folder-item
        v-for="subfolder in children(folder)"
        :key="subfolder.index"
        :folder="subfolder"
        :currentFolder="currentFolder"
      />
    </ul>
  </li>
</template>

<script lang="ts">
import { MailFolder } from '@infocity/mail-common/models/mailFolder';
import settings from '@infocity/mail-common/services/serviceSettings';
import { defineComponent, computed, PropType } from 'vue';

export default defineComponent({
  name: 'folder-item',
  props: {
    folders: {
      type: Array as PropType<MailFolder[]>,
      default: () => []
    },
    folder: {
      type: MailFolder,
      required: true
    },
    currentFolder: {
      type: Number
    },
    /**
     * Czy ma nie wyświetlać foldery podrzędne
    */
    noChildren: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const displayName = computed(() => {
      return settings.lang.folders[props.folder.index] || props.folder.name;
    });

    const opened = computed(() => {
      return (
        (props.folder.index === props.currentFolder ||
          (props.folder.children.length > 0 && props.folder.children?.some(child => child.index === props.currentFolder))
        ) && !props.noChildren
      );
    });

    const classess = computed(() => {
      return {
        active: props.folder.index === props.currentFolder,
        'active-parent': props.folder.children.some(child => child.index === props.currentFolder)
      };
    });

    // Lista podfolderów
    function children(folder: MailFolder): MailFolder[] {
      return props.folders.filter(fldr => fldr.parent === folder.index && !fldr.isRoot);
    }

    return { opened, displayName, classess, children };
  }
});
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.folder-item {
  padding: 2px 0;
  padding-left: 12px;
  border-left: solid 1px map-get($map: $theme-colors, $key: primary);

  a {
    position: relative;
    color: map-get($map: $theme-colors, $key: text);
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
  }

  .active {
    font-weight: bold;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -12px;
      transform: translateY(-50%) translateX(-50%);
      width: 8px;
      height: 8px;
      background: map-get($map: $theme-colors, $key: primary);
      border: solid 1px map-get($map: $theme-colors, $key: light);
      border-radius: 50%;
    }
  }

  .active-parent {
    font-weight: bold;
  }

  .folder-list--child {
    list-style: none;
    margin: 0;
    padding-left: 8px;

    li {
      border: none;
      .active {
        font-weight: bold;
        &:after {
          left: -32px;
        }
      }
    }
  }
}
</style>

/**
 * Typy notyfikacji
 *
 * @enum {number}
 */
export enum NotificationType {
  ERROR = 'error',

  SUCCESS = 'success',

  WARNING = 'warning'
}

<template>
  <div class="panel">
    <header-nav v-if="isLoggedIn">
      <slot name="header" />
    </header-nav>

    <header class="panel-toolbar">
      <slot name="toolbar" />
    </header>

    <div class="panel-content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import HeaderNav from '../main/HeaderNav.vue';
import { isLoggedIn } from '../../store';

/** Główny panel na widoki */
export default defineComponent({
  name: 'panel',
  components: { HeaderNav },

  setup() {
    return { isLoggedIn };
  }
});
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.panel {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: map-get($map: $theme-colors, $key: white);
  box-shadow: map-get($map: $theme-shadows, $key: panel);
  margin-left: width(aside);
  height: 100vh;

  .panel-toolbar {
    border-bottom: solid 1px map-get($map: $theme-colors, $key: border);
  }

  .panel-content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media screen and (max-width: map-get($grid-breakpoints, xl)) {
    margin-left: width(aside-xl);
  }

  @media screen and (max-width: map-get($grid-breakpoints, lg)) {
    margin-left: width(aside-lg);
  }

  @media screen and (max-width: map-get($grid-breakpoints, md)) {
    margin-left: width(aside-md);
  }
}
</style>

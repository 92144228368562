import { MailFolder } from '@infocity/mail-common/models/mailFolder';
import settings from '@infocity/mail-common/services/serviceSettings';
import { ref } from 'vue';

import store from './index';
import mailFolderService from '../services/mailFolder.service';

/**
 * -----------------------------
 * Przechowywane wartości
 * -----------------------------
 */

/**
 * Lista folderów wiadomości
 */
export const list = ref<MailFolder[]>([]);
/**
 * Obsługiwany folder wiadomości
 */
export const current = ref<MailFolder>();

/**
 * -----------------------------
 * Akcje
 * -----------------------------
 */

/**
 * Ładowanie listy folderów
 *
 * @export
 * @returns
 */
export function load(): Promise<void> {
  if (settings.useLocalStorage) {
    const prevList = localStorage.getItem(`mailfolders.${store.getUser()?.Account}`);
    if (prevList) {
      const list = JSON.parse(prevList);
      list.value = MailFolder.fromList(list);
    }
  }
  return mailFolderService.getFolders().then(res => {
    list.value = res;
    if (settings.useLocalStorage) {
      localStorage.setItem(`mailfolders.${store.getUser()?.Account}`, JSON.stringify(res.map(x => x.item)));
    }
  });
}

/**
 * Aktualizacja folderów
 *
 * @export
 * @param {MailFolder[]} folders
 */
export function update(folders: MailFolder[]) {
  for (const folderToUpdate of folders) {
    const listFolder = list.value.find(folder => folder.index === folderToUpdate.index);
    if (listFolder) {
      listFolder.set(folderToUpdate.item);
    }
  }
}

/**
 * Zaznaczenie aktualnego folderu
 *
 * @export
 * @param {number} index
 */
export function select(index: number) {
  current.value = list.value.find(folder => folder.index === index);
}

/**
 * -----------------------------
 * Eksport
 * -----------------------------
 */

/**
 * Foldery wiadomości
 */
export default {
  list,
  current,

  load,
  update,
  select
};

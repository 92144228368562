import { ILoginResult } from '@infocity/mail-common/interfaces/loginResult';
import { computed, ref } from 'vue';

import { signalRService } from '../services/signalR.service';
import { accountService } from '../services/account.service';

/**
 * czy offline
 */
const offline = ref(false);
/**
 * zalogowany uzytkownik, gdy null, to nie zalogowany
 */
const user = ref<ILoginResult | null>(null);

const useWebsocket = ref<boolean>(false);

/**
 * ustawiuenie statusu offline
 * @param state czy offline
 */
export function setOffline(state: boolean) {
  offline.value = state;
}

/**
 * sprawdzenie czy jest offline
 */
export function isOffline(): boolean {
  return offline.value;
}
/**
 * ustawienie użytkownika
 * @param newUser dane uzytkownika
 */
export function setUser(newUser: ILoginResult | null): Promise<void> {
  if (newUser == null) {
    clear();
  }

  user.value = newUser;

  if (newUser) {
    return signalRService.open().then(isConnected => {
      if (isConnected) {
        signalRService.start(newUser.Account, newUser.SessionID, newUser.UserID).then(connId => {
          useWebsocket.value = true;
          // console.log("signalR:connected", connId)
          signalRService.currentUser().then(socketUser => {
            // console.log("signalR:currentUser", socketUser);
          });
          return;
        });
        /*
        signalRService.onNewMessage = (fileIndex: number, subject: string, fromName: string, fromAddress: string, folder: number) => {
          console.log("newMessage", { fileIndex, subject, fromName, fromAddress, folder });
        }
        */
      }
    });
  } else {
    useWebsocket.value = false;
    signalRService.close();
    return Promise.resolve();
  }
}
/**
 * ustawienie użytkownika
 * @param newUser dane uzytkownika
 */
export function getUser(): ILoginResult | null {
  return user.value;
}

export function reloadUser(): Promise<ILoginResult | null> {
  if (user.value) {
    return accountService.checkSession(user.value.Account, user.value.SessionID).then(res => {
      if (res.Status == 'Ok') {
        setUser(res);
      }
      return res;
    });
  } else {
    return Promise.resolve(null);
  }
}

/**
 * wyczyszczenie danych sesji
 */
export function clear() {
  user.value = null;
  offline.value = false;
}

/**
 * sprawdzenie czy użytkownik jest zalogowany
 */
export const isLoggedIn = computed<boolean>(() => !!user.value);

export default {
  setUser,
  getUser,
  isOffline,
  setOffline,
  isLoggedIn,
  useWebsocket,
  clear
};

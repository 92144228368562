import { MessageChangeAction } from '@infocity/mail-common/enums/messageChangeAction.enum';
import { MailListOptions } from '@infocity/mail-common/interfaces/mailListOptions';
import { MessageChangeOptions } from '@infocity/mail-common/interfaces/messageChangeOptions';
import { Mail } from '@infocity/mail-common/models/mail';
import { MailAddressFrom } from '@infocity/mail-common/models/mailAddressFrom';
import { MailContent } from '@infocity/mail-common/models/mailContent';
import { MailFolder } from '@infocity/mail-common/models/mailFolder';
import settings from '@infocity/mail-common/services/serviceSettings';
import { ref } from 'vue';

import folderStore from './mailFolder';
import store from './index';
import mailService from '../services/mail.service';
import accountService from '../services/account.service';
import { SearchStatus, signalRService } from '../services/signalR.service';
import { MailSearchOptions } from '../models/mailSearchOptions';

/**
 * -----------------------------
 * Przechowywane wartości
 * -----------------------------
 */

/**
 * Lista wiadomości
 */
export const list = ref<Mail[]>([]);
/**
 * Obsługiwana wiadomość
 */
export const current = ref<Mail>();
/**
 * Treść obsługiwanej wiadomości
 */
export const currentContent = ref<MailContent>();

/**
 * Ilość wiadomości w folderze
 */
export const totalCount = ref<number>(-1);

/**
 * Aktalnie używany folder
 */
const currentFolder = folderStore.current;

/**
 * Aktualne wyszukiwanie
 */
const currentSearch = ref<string>('');

export let currentOptions = {
  page: 0,
  folder: -1,
  query: '' as string | undefined,
  sst: '' as string | undefined
};

//let currentSearch: string = "";
let requestId = 0;
let lastLoadOptions: { folder: number; options: MailListOptions };

/**
 * -----------------------------
 * Akcje
 * -----------------------------
 */

/**
 * Ładowanie listy wiadomości
 *
 * @export
 * @param {number} folder
 * @param {MailListOptions} options
 * @returns
 */
export function load(folder: number, options: MailListOptions): Promise<void> {
  folderStore.select(folder);

  if (!options) {
    options = { count: 50, start: 0 };
  }
  lastLoadOptions = { folder, options };
  const currentRequestId = ++requestId;

  const pageSize = options?.count || 50;
  let pageStart = options?.start || 0;
  let fixedPageStart = pageStart;

  //fix pageStart -> continue

  if (
    pageStart &&
    currentOptions.folder == folder &&
    currentOptions.sst == options.sst &&
    currentOptions.query == options.query &&
    pageStart == currentOptions.page + pageSize &&
    list.value.length == pageSize
  ) {
    fixedPageStart = -list.value[pageSize - 1].index;
    options.prevId = list.value[pageSize - 1].index;
  }
  currentOptions.page = pageStart;
  currentOptions.sst = options.sst;
  currentOptions.query = options.query;
  currentOptions.folder = folder;

  if (currentSearch.value) {
    signalRService.cancelSearch(currentSearch.value);
    currentSearch.value = '';
  }

  if (settings.useLocalStorage && pageStart === 0 && !options.query && !options.sst) {
    const prevResult = localStorage.getItem(`maillist.${store.getUser()?.Account}.${folder}`);
    if (prevResult) {
      try {
        const prevList = Mail.fromList(JSON.parse(prevResult));

        Mail.setGroups(prevList);
        list.value = prevList;
      } catch (err) {}
    }
  }

  if (store.useWebsocket.value && (options.sst || options.query)) {
    let searchFunc: Promise<string>;
    if (options.sst) {
      searchFunc = signalRService.startFullSearch(MailSearchOptions.fromString(options.sst), folder, {
        ps: pageSize,
        s: fixedPageStart
      });
    } else if (options.query) {
      searchFunc = signalRService.startSearch(options.query, folder, { ps: pageSize, s: fixedPageStart });
    } else {
      return Promise.resolve();
    }

    return searchFunc.then((searchId: string) => {
      currentSearch.value = searchId;
      var msglist: Mail[] = (list.value = []);
      // totalCount.value = pageStart + pageSize;
      totalCount.value = -1;

      console.log(
        'signalR:startSearch',
        searchId,
        options.sst ? { sst: options.sst, obj: MailSearchOptions.fromString(options.sst) } : { query: options.query }
      );
      //console.log('signalR:startSearch', { query: options.query, searchId });
      const searchStart = new Date();
      signalRService.onSearchStatus = (searchId2: string, status: SearchStatus, statusData: string) => {
        console.log('signalR:onSearchStatus', searchId2, status, statusData);
        if (searchId2 === searchId) {
          // console.log('signalR:onSearchStatus', status, ((new Date().getTime()) - searchStart.getTime()).toString());
          if (status == SearchStatus.Cancelled || status == SearchStatus.Finished) {
            searchId = '';
            currentSearch.value = '';
            if (list.value.length < pageSize) {
              totalCount.value = pageStart + list.value.length;
            } else {
              totalCount.value = -1;
            }
          } else if (status == SearchStatus.Searching) {
            let data = statusData.split(',');
            let count = parseInt(data[0]);
            let msgData = new Date(parseInt(data[1]) * 1000);
            console.log('searching', searchId2, count, msgData);
          }
        } else if (status == SearchStatus.Searching) {
          // przerwij gdy trwa inne
          signalRService.cancelSearch(searchId2);
        }
      };
      signalRService.onFound = (searchId2: string, msg: Mail) => {
        if (searchId2 === currentSearch.value) {
          list.value.push(msg);
          Mail.setGroups(list.value);
        } else {
          // przerwij gdy pojawiają się inne dane
          signalRService.cancelSearch(searchId2);
        }
      };
      return;
    });
  } else {
    return mailService
      .list(folder, options)
      .then(res => {
        if (currentRequestId !== requestId) {
          return;
        }
        const foldersToUpdate = res.folderList || (res.folderInfo ? [new MailFolder(res.folderInfo)] : undefined);
        updateStoreAfterList(res.list, foldersToUpdate, options);

        if (pageStart === 0 && settings.useLocalStorage && !options.query && !options.sst) {
          localStorage.setItem(
            `maillist.${store.getUser()?.Account}.${folder}`,
            JSON.stringify(res.list.map(x => x.item))
          );
        }
      })
      .catch(err => {
        console.warn('store/mail:', err);
      });
  }
}

/**
 * Anulowanie wyszukiwania
 * @param searchId id wyszukiwania, gdy pominięte to bierze `currentSearch`
 * @returns
 */
export function cancelSearch(searchId?: string): Promise<boolean> {
  if (searchId === undefined) {
    searchId = currentSearch.value;
  }
  if (!searchId || !store.useWebsocket.value) {
    return Promise.resolve(false);
  }
  return signalRService.cancelSearch(searchId).then(stopped => {
    if (stopped) {
      if (searchId === currentSearch.value) {
        currentSearch.value = '';
      }
    }
    return stopped;
  });
}

/**
 * Wykonanie akcji na wiadomości
 *
 * @export
 * @param {MessageChangeOptions} options
 * @returns
 */
export function change(options: MessageChangeOptions): Promise<void> {
  prepareChangeAction(options);

  const needListUpdate = options.action === MessageChangeAction.DELETE || options.action === MessageChangeAction.MOVE;
  return mailService.change(options).then(response => {
    if (response.mail) {
      const mailItem = list.value.find(mail => mail.msgf === response.mail.msgf);
      if (mailItem) {
        mailItem.set(response.mail.item);
      }
    }
    if (response.folders?.length) {
      folderStore.update(response.folders);
    }
    if (needListUpdate && lastLoadOptions) {
      // TODO: usunąć jeden rekord i załadować :)
      load(lastLoadOptions.folder, lastLoadOptions.options);
    }
  });
}

/**
 * Wykonywanie akcji na wielu wiadomościach
 *
 * @export
 * @param {MessageChangeOptions} options
 * @returns {Promise<void>}
 */
export function changeMultiple(options: MessageChangeOptions): Promise<void> {
  prepareChangeAction(options);
  return mailService
    .changeMultiple(options, currentFolder.value?.index || -1, lastLoadOptions?.options)
    .then(response => {
      updateStoreAfterList(response.mails, response.folders, lastLoadOptions?.options);
    });
}

/**
 * Zaznacz/odznacz wiadomość
 *
 * @export
 * @param {string} msgf
 * @param {boolean} [check]
 */
export function check(msgf: string, check?: boolean): void {
  const mailItem = list.value.find(mail => mail.msgf === msgf);
  if (mailItem) {
    if (check !== undefined) {
      mailItem.checked = check;
    } else {
      mailItem.checked = !mailItem.checked;
    }
  }
}

/**
 * Zaznacz/odznacz wszystkie wiadomości w liście
 *
 * @export
 * @param {boolean} check
 */
export function checkAll(check: boolean): void {
  list.value.forEach(mail => {
    mail.checked = check;
  });
}

/**
 * Ustawia aktualnie wyświetlaną wiadomość
 *
 * @export
 * @param {Mail} mailToSelect
 */
export function select(mailToSelect?: Mail, contentToSelect?: MailContent): void {
  current.value = mailToSelect;
  currentContent.value = contentToSelect;
}

export function getFromList(): Promise<MailAddressFrom[]> {
  var prevList = localStorage.getItem(`maillist.${store.getUser()?.Account}`);
  if (prevList) {
    return JSON.parse(prevList);
  }

  return accountService.fromList().then(res => {
    localStorage.setItem(`maillist.${store.getUser()?.Account}`, JSON.stringify(res.map(x => x.item)));
    return res;
  });
}

/**
 * -----------------------------
 * Funkcje pomocnicze
 * -----------------------------
 * Funkcje te nie powinny być eksportowane
 */

/**
 * Przygotwuje ustawienia akcji
 *
 * @param {MessageChangeOptions} options
 */
function prepareChangeAction(options: MessageChangeOptions): void {
  if (options.action === MessageChangeAction.DELETE && !currentFolder.value?.isTrash) {
    options.action = MessageChangeAction.MOVE;
    options.actionValue = '3'; // TODO: Może jakoś lepiej :)
  }
}

/**
 * Aktualizuje listę wiadomości, ilość wiadomości, listę folderów
 *
 * @param {Mail[]} resultList
 * @param {(MailFolder[] | undefined)} resultFolders
 * @param {MailListOptions} options
 */
function updateStoreAfterList(resultList: Mail[], resultFolders: MailFolder[] | undefined, options: MailListOptions) {
  if (resultFolders) {
    folderStore.update(resultFolders);
  }
  const pageSize = options?.count || 50;
  const pageStart = options?.start || 0;
  if (options.query || options.sst) {
    if (resultList.length < pageSize) {
      totalCount.value = pageStart + resultList.length;
    } else {
      totalCount.value = -1;
    }
  } else if (currentFolder.value) {
    totalCount.value = currentFolder.value.count;
  } else {
    totalCount.value = -1;
  }

  list.value = resultList;
}

/**
 * -----------------------------
 * Eksport
 * -----------------------------
 */

/**
 *  Wiadomości
 */
export default {
  list,
  current,
  currentContent,
  totalCount,

  load,
  change,
  changeMultiple,
  check,
  checkAll,
  select
};

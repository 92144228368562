import { ILoginResult, IWebMailIni } from '@infocity/mail-common/interfaces/loginResult';
import { ISignature } from '@infocity/mail-common/interfaces/signature';
import { IStatusResult } from '@infocity/mail-common/interfaces/statusResult';
import { IUserSettings } from '@infocity/mail-common/interfaces/userSettings';
import currentDay from '@infocity/mail-common/methods/currentDay';
import { MailAddress } from '@infocity/mail-common/models/mailAddress';
import { MailAddressFrom } from '@infocity/mail-common/models/mailAddressFrom';
import { RemoteAccount } from '@infocity/mail-common/models/remoteAccount';
import { BaseService } from '@infocity/mail-common/services/base.service';
import { ServiceSettings } from '@infocity/mail-common/services/serviceSettings';

import { addUser } from '@/store/users';

/**
 * zarządzanie kontem urzytkownika
 */
export class AccountService extends BaseService {
  constructor(settings?: ServiceSettings) {
    super(settings);
  }
  /**
   * logowanie uzytkownika
   * @param user email
   * @param password hasło
   * @param remember czy zapamiętać
   */
  login(user: string, password: string, remember: boolean = false): Promise<ILoginResult> {
    return this.postJson<ILoginResult>('Msg/Login', { user, password, remember: remember ? 1 : 0 }).then(res => {
      if (res.Status === 'Ok') {
        currentDay.refresh();
        this.settings.session.set(res);
        addUser(res);
      }
      return res;
    });
  }
  /**
   * wylogowanie
   */
  logout(): Promise<IStatusResult> {
    return this.postJson<IStatusResult>('Msg/Logout').then(res => {
      if (res.Status === 'Ok') {
        this.settings.session.clear();
      }
      return res;
    });
  }
  /**
   * sprawdzenie czy sesja jest aktywna
   * @param uid userid
   * @param sid sessionid
   */
  checkSession(uid: string, sid: string): Promise<ILoginResult> {
    return this.getJson<ILoginResult>('Msg/TestSession', { uid, sid }).then(res => {
      if (res.Status === 'Ok') {
        currentDay.refresh();
        this.settings.session.set(res);
      }
      return res;
    });
  }
  /**
   * pobranie ustawień użytkownika
   */
  getUserSettings(): Promise<IUserSettings> {
    return this.getJson('Settings/Get');
  }
  /**
   * pobranie zdalnych kont
   */
  remoteAccountList(): Promise<RemoteAccount[]> {
    return this.getJson<any[][]>('Settings/RemoteList').then(res => RemoteAccount.fromList(res));
  }
  /**
   * lista adresów z których użytkownik może wysyłac wiadomości
   *
   * @returns {Promise<MailAddressFrom[]>}
   * @memberof AccountService
   */
  fromList(): Promise<MailAddressFrom[]> {
    return this.getJson<any[][]>('Msg/FromList').then(res => {
      return MailAddressFrom.fromList(res);
    });
  }
  /**
   * lista adresów z których użytkownik może wysyłac wiadomości
   *
   * @returns {Promise<MailAddressFrom[]>}
   * @memberof AccountService
   */
  toList(count: number = 50): Promise<MailAddress[]> {
    return this.getJson<any[][]>('Card/Last', { count }).then(res => {
      return res.map(x => {
        return new MailAddress([x[1], x[0]]);
      });
    });
  }
  /**
   * lista podpisów
   */
  signatureList(): Promise<ISignature[]> {
    return this.getJson<ISignature[]>('Settings/SignatureList');
  }
  /**
   * dodanie do listy akceptowanych nadawców
   * @param emailOrDomain email lub domena
   */
  whitelistAdd(emailOrDomain: string): Promise<IStatusResult> {
    return this.getJson('Settings/WhiteListAdd', { item: emailOrDomain });
  }
  /**
   * dodanie do listy zablokowanych zadawców
   * @param emailOrDomain email lib domena
   */
  blacklistAdd(emailOrDomain: string): Promise<IStatusResult> {
    return this.getJson('Settings/BlackListAdd', { item: emailOrDomain });
  }

  /**
   * pobranie ustawień
   * @returns
   */
  getWebMailSettings(): Promise<IWebMailIni> {
    return this.getJson<IWebMailIni>('Settings/WebMailSettings').then(wb => {
      // może zapis do usera ?
      return wb;
    });
  }

  /**
   * zapisz ustawień
   * @param section sekcja
   * @param name nazwa klucza
   * @param value wartosc
   * @returns
   */
  setWebMailSettings(section: string, name: string, value: string | null = null): Promise<IStatusResult> {
    let data: any = {
      section,
      name
    };
    if (value != null) {
      data.value = value;
    }
    return this.postJson('Settings/WebMailSettings', data);
  }
}

export const accountService = new AccountService();
export default accountService;

import { ref, Ref } from 'vue';

import { setLoggedCookies } from "@/methods/cookies";
import accountService from "@/services/account.service";
import { setUser } from "@/store";

/**
 * Logowanie użytkownika
 *
 * @export
 * @param {() => Promise<void>} onLogin calback wykonywany po zalogowaniu
 * @return {{ email: Ref<string>, password: Ref<string>, login: () => Promise<void> }} email i hasło użytkownika, funkcaj do logowania użytkownika
 */
export function useLogin(onLogin: () => Promise<void>): { email: Ref<string>, password: Ref<string>, login: () => Promise<void> } {

  const email = ref('');

  const password = ref('');

  /**
   * Loguje użytkownika 
   */
  function login(): Promise<void> {
    return accountService.login(email.value, password.value).then(res => {
      if (res.Status === 'Ok') {
        setLoggedCookies(res);
        return setUser(res).then(() => onLogin());
      }
      if (res.Err === 'badpassword') {
        return alert('Niepoprawny login i/lub hasło.');
      }
      return alert('Błąd logowania.');
    })
  }
  
  return { email, password, login };
}

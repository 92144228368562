import { ILoginResult } from '@infocity/mail-common/interfaces/loginResult';
import { ref, computed } from 'vue';

import accountService from '@/services/account.service';
import { setLoggedCookies } from '@/methods/cookies';
import { setUser, getUser } from '@/store/index';

/**
 * Obecny użytkownik
 */
export const currentUser = computed(() => getUser());

const _activeUsers = ref<ILoginResult[]>(loadUsers() || []);

/**
 * Lista zalogownych użytkowników
 */
export const activeUsers = computed(() => _activeUsers.value);

/**
 * Dodaje użytkownika do listy zalogownych
 *
 * @export
 * @param {ILoginResult} newUser użytkownik
 * @return {void} 
 */
export function addUser(newUser: ILoginResult): void {
  if (_activeUsers.value.some(user => user.UserID === newUser.UserID)) {
    return;
  }
  _activeUsers.value.push(newUser);
  saveUsers();
}

/**
 * Usuwa użytkownia z listy zalogowanych użytkowników
 * 
 * @export
 * @param {(ILoginResult | null)} unWantedUser użytkownik do usunięcia
 * @return {void} 
 */
export function removeUser(unWantedUser: ILoginResult | null): void {
  if (!unWantedUser) {
    return;
  }
  _activeUsers.value = _activeUsers.value.filter(user => user.UserID !== unWantedUser.UserID);
  saveUsers();
}

/**
 * Zmienia obecnie ustawianonm sesję w celu zmiany użytkownika
 *
 * @export
 * @param {ILoginResult} user użytkownik którego konto ma zostać wybrane
 * @return {Promise<void>} 
 */
export function changeUser(user: ILoginResult): Promise<void> {
  if (!user || user.UserID === currentUser.value?.UserID) {
    return Promise.resolve();
  }
  accountService.settings.session.set(user);
  setLoggedCookies(user);
  return setUser(user);
}

/**
 * Zapisuje zalogownych użytkowników w pamięci przeglądarki
 *
 * @export
 * @return {void} 
 */
export function saveUsers(): void {
  localStorage.setItem('users', JSON.stringify(_activeUsers.value));
}


/**
 * Czyści zalogownych użytkowników z pamięci przeglądarki
 *
 * @export
 * @return {void} 
 */
export function clearUsers(): void {
  _activeUsers.value = [];
  localStorage.removeItem('users');
}

/**
 * Załadowywuje zalogownych użytkowików z paięci przeglądarki
 *
 * @return {(ILoginResult[] | undefined)} 
 */
function loadUsers(): ILoginResult[] | undefined {
  try {
    const res = JSON.parse(localStorage.getItem('users') || '');
    return res;
  }
  catch (err) {
    return undefined;
  }
}

export default {
  activeUsers,
  currentUser,

  addUser,
  removeUser,
  changeUser,
  saveUsers,
  clearUsers,
  loadUsers
}

import { RouteRecordRaw } from 'vue-router';

import { checkLogged } from '@/methods/checkLogged';
import contact from '@/store/contact';

/**
 * Kontakty
 */
export const contactRoute: RouteRecordRaw = {
  name: 'contact',
  path: '/contact',
  component: () => import('@/views/Contact/Contact.vue'),
  beforeEnter: to => checkLogged(to).then(() => contact.loadCardGroups()),
  redirect: { name: 'contact:list', params: { groupId: 0 } },
  children: [
    {
      name: 'contact:list',
      path: ':groupId',
      component: () => import('@/views/Contact/ContactList.vue'),
      props: route => {
        return {
          search: route.query.search
        }
      },
      children: [
        {
          name: 'contact:view',
          path: ':contactId',
          component: () => import('@/views/Contact/ContactView.vue'),
          props: route => {
            return {
              contactId: parseInt(route.params.contactId as string)
            };
          }
        }
      ]
    }
  ]
};




export enum SearchStringCompareMode {
    Contains = 1,
    NotContains = 2,
    StartWith = 3,
    EndsWith = 4,
    IsEqual = 5,
    IsNotEqual = 6,

    Before = 1,
    Since = 2,
    Day = 3,

    Larger = 1,
    Smaller = 2,

    All = 0

}

export class SearchField {
    value: string = "";
    mode?: SearchStringCompareMode;

    constructor(mode: SearchStringCompareMode = SearchStringCompareMode.Contains) {
        this.mode = mode;
        this.value = "";
    }

    toString() {
        if (this.hasValue()) {
            return this.mode + this.value;
        }
        return "";
    }
    hasValue(): boolean {
        return (this.value && this.mode !== SearchStringCompareMode.All) as boolean;

    }
    static fromString(text: string, defMode?: SearchStringCompareMode): SearchField | undefined {
        if (text) {
            let newItem = new SearchField();
            newItem.mode = parseInt(text.substring(0, 1));
            newItem.value = text.substring(1);
            return newItem;
        } else if (defMode != undefined) {
            return new SearchField(defMode);
        }
    }

}

export class MailSearchOptions extends Object {

    statusTime?: number;
    subject?: SearchField;
    from?: SearchField;
    to?: SearchField;
    body?: SearchField;
    attachment?: SearchField;
    date?: SearchField;
    size?: SearchField;

    static parseItem(item: string): SearchField | undefined {

        return SearchField.fromString(item);

    }



    static fromString(text: string, createAll: boolean = false): MailSearchOptions {
        let tsst = text.split('|');
        let value = new MailSearchOptions();

        value.from = SearchField.fromString(tsst[0], createAll ? SearchStringCompareMode.Contains : undefined);
        value.to = SearchField.fromString(tsst[1], createAll ? SearchStringCompareMode.Contains : undefined);
        value.subject = SearchField.fromString(tsst[2], createAll ? SearchStringCompareMode.Contains : undefined);
        value.body = SearchField.fromString(tsst[3], createAll ? SearchStringCompareMode.Contains : undefined);
        value.attachment = SearchField.fromString(tsst[4], createAll ? SearchStringCompareMode.Contains : undefined);
        value.date = SearchField.fromString(tsst[5], createAll ? SearchStringCompareMode.Since : undefined);
        value.size = SearchField.fromString(tsst[6], createAll ? SearchStringCompareMode.Larger : undefined);
        /*
                [value.from,
                value.to,
                value.subject,
                value.body,
                value.attachment,
                value.date,
                value.size] = tsst.map(x => SearchField.fromString(x, createAll));
                */
        /*
                value.from = SearchField.fromString(tsst[0]);
                value.to = SearchField.fromString(tsst[1]);
                value.subject = SearchField.fromString(tsst[2]);
                value.size = SearchField.fromString(tsst[3]);
                value.body = SearchField.fromString(tsst[4]);
                value.attachment = SearchField.fromString(tsst[5]);
                value.date = SearchField.fromString(tsst[6]);
                */
        // debugger;
        return value;


    }

    static empty(dt?: Date): Required<MailSearchOptions> {
        if (dt === undefined) {
            dt = new Date();
            dt.setMonth(dt.getMonth() - 3);
        }
        let dts = dt.getFullYear() + "-" + (dt.getMonth() < 9 ? "0" + (dt.getMonth() + 1) : (dt.getMonth() + 1)) + "-" + (dt.getDate() < 10 ? "0" + (dt.getDate()) : (dt.getDate()));

        var opt = new MailSearchOptions();
        opt.from = new SearchField();
        opt.to = new SearchField();
        opt.subject = new SearchField();
        opt.date = new SearchField(SearchStringCompareMode.Since);
        opt.date.value = dts;
        opt.size = new SearchField(SearchStringCompareMode.Larger);
        opt.body = new SearchField();
        opt.attachment = new SearchField();

        return opt as Required<MailSearchOptions>;
    }



    hasValue(): boolean {
        return !!((this.from?.hasValue()) ||
            (this.to?.hasValue()) ||
            (this.subject?.hasValue()) ||
            (this.size?.hasValue()) ||
            (this.body?.hasValue()) ||
            (this.attachment?.hasValue()));

    }



    override toString(): string {
        return [this.from, this.to, this.subject, this.body, this.attachment, this.date, this.size]
            //.map(x => x ? x.toString() : "")
            .join('|');
    }

}
<template>
  <a href="/" class="logo-wrapper">
    <img v-if="white" src="../assets/logo-white.svg" alt="Poczta Infocity" class="company-logo logo-white" />

    <img v-else-if="dark" src="../assets/logo-dark.svg" alt="Poczta Infocity" class="company-logo logo-dark" />

    <img v-else src="../assets/logo.svg" alt="Poczta Infocity" class="company-logo" />
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    white: { type: Boolean },
    dark: { type: Boolean }
  }
});
</script>

<style lang="scss">
.company-logo {
  width: 180px;
}
</style>

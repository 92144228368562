import settings from '@infocity/mail-common/services/serviceSettings';
import { createApp } from 'vue';

import Icon from './components/helpers/Icon.vue';
import App from './App.vue';
import { router, goLogin } from './router/router';
import store from './store';
import LogoSite from './components/Logo.vue';
import AppAside from './components/interface/AppAside.vue';
import ButtonCta from './components/interface/ButtonCta.vue';
import Panel from './components/interface/Panel.vue';
import pl from './lang/pl';

const app = createApp(App);
settings.setup({
  apiUrl: import.meta.env.VITE_API_URL,
  webSocketUrl: import.meta.env.VITE_WEB_SOCKET_URL,
  signalRUrl: import.meta.env.VITE_SIGBALR_URL,
  cookieless: true,
  lang: pl,
  userDateDiff: 0
});
app.use(router);

app.component('w-icon', Icon);

app.component('AppAside', AppAside);
app.component('ButtonCta', ButtonCta);
app.component('LogoSite', LogoSite);
app.component('Panel', Panel);

app.mount('#app');

settings.onError402((action, xhr) => {
  goLogin();
});

settings.onNoConnection((action, xhr) => {
  store.setOffline(true);
});

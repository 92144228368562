import { Card } from '@infocity/mail-common/models/card';
import { CardGroup } from '@infocity/mail-common/models/cardGroup';
import { computed, ref } from 'vue';

import cardService from '@/services/card.service';
import cardGroupService from '@/services/cardGroup.service';

/**
 * -----------------------------
 * Przechowywane wartości
 * -----------------------------
 */

/**
 * Lista grup użytkownika
 */
const _cardGroups = ref<Array<CardGroup>>([]);
const cardGroups = computed(() => _cardGroups.value);

/**
 * Lista kontaktów użytkownika
 */
const _cards = ref<Array<Card>>([]);
const cards = computed(() => _cards.value);

/**
 * -----------------------------
 * Akcje
 * -----------------------------
 */

/**
 * Ładowanie listy grup
 *
 * @export
 * @return {Promise<void>}
 */
function loadCardGroups(): Promise<void> {
  return cardGroupService.list().then(result => {
    _cardGroups.value = result;
  });
}

/**
 * Ładowanie listy kontaktów
 *
 * @param {number} [groupId]
 * @return {Promise<void>}
 */
function loadCards(groupId?: number): Promise<void> {
  _cards.value = [];
  return cardService.list().then(list => {
    if (!groupId) {
      _cards.value = list;
      return;
    }
    _cards.value = list.filter(card => card.addrGroup.includes(`,${groupId},`));
  });
}

/**
 * -----------------------------
 * Eksport
 * -----------------------------
 */

export default {
  cardGroups,
  cards,

  loadCardGroups,
  loadCards
};

import { MessageChangeAction } from '@infocity/mail-common/enums/messageChangeAction.enum';
import { IBoxResult } from '@infocity/mail-common/interfaces/boxResult';
import { MailListOptions } from '@infocity/mail-common/interfaces/mailListOptions';
import { MessageChangeMultipleResponse } from '@infocity/mail-common/interfaces/messageChangeMultipleResponse';
import { MessageChangeOptions } from '@infocity/mail-common/interfaces/messageChangeOptions';
import { MessageChangeResponse } from '@infocity/mail-common/interfaces/messageChangeResponse';
import { IStatusResult } from '@infocity/mail-common/interfaces/statusResult';
import { ITodayResult } from '@infocity/mail-common/interfaces/todayResult';
import currentDay from '@infocity/mail-common/methods/currentDay';
import { GroupInfo } from '@infocity/mail-common/models/groupInfo';
import { Mail } from '@infocity/mail-common/models/mail';
import { MailAttachment } from '@infocity/mail-common/models/mailAttachment';
import { MailContent } from '@infocity/mail-common/models/mailContent';
import { MailFolder } from '@infocity/mail-common/models/mailFolder';
import { MailWinmail } from '@infocity/mail-common/models/mailWinmail';
import { BaseService } from '@infocity/mail-common/services/base.service';
import { ServiceSettings } from '@infocity/mail-common/services/serviceSettings';

import { MailEditModel } from '../models/mailEditModel';
import { MailSearchOptions } from '../models/mailSearchOptions';

/**
 * Obsługa wiadomości
 *
 * @export
 * @class MailService
 * @extends {BaseService}
 */
export class MailService extends BaseService {
  constructor(settings?: ServiceSettings) {
    super(settings);
  }

  /**
   * pobranie informacji today
   *
   * @param options opcje
   */
  today(options?: any): Promise<ITodayResult> {
    const reqparams: string[] = [];
    let folder = -1;
    if (options) {
      if (options.folder !== undefined) {
        folder = options.folder;
      }
      if (options.dt) {
        reqparams.push('dt=' + encodeURIComponent(options.dt));
      }
      if (options.folderList) {
        reqparams.push('fl=1');
      } else {
        reqparams.push('fl=0');
      }
      if (options.s !== undefined) {
        reqparams.push('s=' + options.s);
      }
      if (options.start) {
        reqparams.push('s=' + options.start);
      }
      if (options.count) {
        reqparams.push('ps=' + options.count);
      }
    }

    const resultPromise = this.getJson<any>(`Msg/Today/${folder}?` + reqparams.join('&')).then((res: ITodayResult) => {
      if (res.new) {
        res.new = Mail.fromList(res.new as any[]);
      }
      if (res.l) {
        res.l = Mail.fromList(res.l as any[]);
      }
      if (res.fl) {
        res.fl = MailFolder.fromList(res.l as any[]);
      }
      if (options && options.callback) {
        options.callback(res);
      }
      return res;
    });
    currentDay.refresh(); // bo i tak nie mam co robić
    return resultPromise;
  }

  /**
   * pobranie listy wiadomości
   *
   * @param folder folder
   * @param options opcje
   */
  list(folder: number, options?: MailListOptions): Promise<IBoxResult> {
    const { url, reqparams } = this.prepareLoadParams(folder, options);
    const resultPromise = this.getJson<any>(url + reqparams.join('&')).then((res: any) => {
      const result: IBoxResult = {
        total: res.total,
        list: Mail.fromList(res.l)
      };
      if (res.fl || (res.f && res.f[0] && Array.isArray(res.f[0]))) {
        // Hack na `res.f` - zwraca tablicę folderów zamiast pojedynczego folderu
        result.folderList = MailFolder.fromList(res.fl || res.f);
      } else if (res.f) {
        result.folderInfo = res.f;
      }
      Mail.setGroups(result.list);
      if (options && options.callback) {
        options.callback(result);
      }
      return result;
    });
    currentDay.refresh(); // bo i tak nie mam co robić
    return resultPromise;
  }

  /**
   * wyszukiwanie wiadomości
   *
   * @param folder folder, -1 gdy nie podany
   * @param query tekst do wyszukania
   * @param options opcje
   */
  simpleSearch(folder: number, query: string, options?: MailListOptions): Promise<IBoxResult> {
    if (!options) {
      options = { query };
    } else {
      options.query = query;
    }
    return this.list(folder, options);
  }

  /**
   * pobierz treść wiadomości
   *
   * @param msgf nazwa pliku maila
   * @param index id wiadomości
   */
  getMailContent(msgf: string, index: number = -1): Promise<MailContent> {
    const boundary: string = '\r\n----mailboundary----\r\n';
    return this.getAjax(`Msg/Ajax/${index}?msgf=${encodeURIComponent(msgf)}&nofixstyle=1&ajax=true&`).then(res => {
      const tt = res.split(boundary);
      const jsonres = JSON.parse(tt[0]);

      const content = new MailContent(jsonres);
      content.msgf = msgf;

      content.textBody = tt[1];
      content.htmlBody = tt[2];
      if (content.att && content.att.length) {
        // fix att content
        for (let i = 0; i < content.att.length; i++) {
          if (tt[i + 3]) {
            content.att[i].content = tt[i + 3];
          }
        }
        /*
        let winmailIndex = content.att.findIndex(x => x.fileName == 'winmail.dat');
        if (winmailIndex > -1) {
          return mailService.winmailInfo(index, msgf, winmailIndex).then(res2 => {
            content.winMail = res2;
            return content;
          })
        }
        */
      }

      return content;
    });
  }

  /**
   * pobierz źródło wiadomości
   * @param msgf nazwa pliku wiadomości
   * @param linesCount ile maksymalnie lini tekstu zwrócić
   */
  getMailSource(msgf: string, linesCount: number = 500): Promise<string | undefined> {
    return this.getAjax('Msg/Lines/-1', { msgf, count: linesCount });
  }

  /**
   * link do pobrania załącznika
   *
   * @param {string} msgf
   * @param {MailAttachment} att
   * @param {boolean} [noDownload]
   * @returns {string}
   * @memberof MailService
   */
  attUrl(index: number, msgf: string, att: MailAttachment, noDownload?: boolean): string {
    const action = `Att/Get/${index}`;

    const data: any = {
      msgf
    };
    if (att.structure) {
      data.structure = att.structure;
    } else {
      data.index = att.index;
    }
    if (noDownload) {
      data.nodownload = true;
    }
    return this.url(action, data);
  }

  /**
   * link do pobrania miniaturki grafiki z załącznika wiadomości
   *
   * @param {string} msgf
   * @param {MailAttachment} att
   * @returns {string}
   * @memberof MailService
   */
  thumbUrl(msgf: string, att: MailAttachment): string {
    const action = `Att/Thumb/-1`;

    const data: any = {
      msgf
    };
    if (att.structure) {
      data.structure = att.structure;
    } else {
      data.index = att.index;
    }

    return this.url(action, data);
  }

  /**
   * link do pobrania podglądu grafiki z załącnzika wiadomości
   *
   * @param {string} msgf
   * @param {MailAttachment} att
   * @param {number} [maxWidth]
   * @returns {string}
   * @memberof MailService
   */
  previewUrl(msgf: string, att: MailAttachment, maxWidth?: number): string {
    const action = `Att/Preview/-1`;

    const data: any = {
      msgf
    };
    if (att.structure) {
      data.structure = att.structure;
    } else {
      data.index = att.index;
    }
    if (maxWidth) {
      data.width = maxWidth;
    }

    return this.url(action, data);
  }

  /**
   * link do pobrania wszystkich załączników
   *
   * @param {number} index
   * @param {string} msgf
   * @returns
   * @memberof MailService
   */
  downloadAllUrl(index: number, msgf: string) {
    return this.url(`Att/Zip/${index}`, { msgf: msgf });
  }

  /**
   * link do pobrania źródła wiadomości
   *
   * @param {string} msgf
   * @param {string} [destFileName]
   * @returns {string}
   * @memberof MailService
   */
  mailSourceUrl(msgf: string, destFileName?: string): string {
    const action = `Msg/Source/-1`;

    const data: any = {
      msgf
    };
    if (destFileName) {
      data.filename = destFileName;
    }

    return this.url(action, data);
  }

  /**
   * url do pobrania raportu dotyczącego wiadomości
   *
   * @param {string} msgf
   * @param {boolean} [showsize]
   * @returns {string}
   * @memberof MailService
   */
  mailReportUrl(msgf: string, showsize?: boolean): string {
    const action = `Msg/Report/-1`;

    const data: any = {
      msgf
    };
    if (showsize !== undefined) {
      data.showsize = showsize ? 'true' : 'false';
    }

    return this.url(action, data);
  }

  /**
   * pobranie wątku wiadomości
   *
   * @param {number} index
   * @returns {Promise<Mail[]>}
   * @memberof MailService
   */
  async thread(index: number): Promise<Mail[]> {
    const res = await this.getJson<any>(`Msg/Thread/${index}`);
    const all: any = {};
    const result: Mail[] = [];
    const fixItem = (item_1: any[]): Mail => {
      const nitem = new Mail(item_1);
      nitem.threadLevel = 0;
      if (nitem.referenceId) {
        const prevItem = all[nitem.referenceId];
        if (prevItem) {
          nitem.threadLevel = (prevItem.threadLevel || 0) + 1;
        }
      }

      if (nitem.messageId) {
        all[nitem.messageId] = nitem;
      }
      result.push(nitem);
      return nitem;
    };
    // fix levels
    if (res.p) {
      for (let i = 0; i < res.p.length; i++) {
        res.p[i] = fixItem(res.p[i]);
      }
    }
    if (res.msgitem) {
      res.msgitem = fixItem(res.msgitem);
    }
    if (res.n) {
      for (let i_1 = 0; i_1 < res.n.length; i_1++) {
        res.n[i_1] = fixItem(res.n[i_1]);
      }
    }
    return result;
  }

  /**
   * pobranie wiadomości grupowych
   *
   * @param {string} group
   * @param {string} messageId
   * @returns {Promise<GroupInfo[]>}
   * @memberof MailService
   */
  groupInfo(group: string, messageId: string): Promise<GroupInfo[]> {
    return this.getJson<any>('Msg/GroupInfo/-1', { group, msgid: messageId }).then(res => GroupInfo.fromList(res));
  }
  /**
   * Oznacz wiadomość jako przeczytaną
   *
   * @param {number} index
   * @returns {Promise<MessageChangeResponse>}
   * @memberof MailService
   */
  readMail(index: number): Promise<MessageChangeResponse> {
    return this.change({ index, action: MessageChangeAction.READ });
  }

  /**
   * ustawianie wiadomości jako nieprzeczytana
   *
   * @param {number} index
   * @returns {Promise<any>}
   * @memberof MailService
   */
  unreadMail(index: number): Promise<any> {
    return this.change({ index, action: MessageChangeAction.UNREAD });
  }

  /**
   * ustawienie informacji w grupie o przeczytaniu
   *
   * @param {string} group
   * @param {string} messageId
   * @returns {Promise<IStatusResult>}
   * @memberof MailService
   */
  readGroupInfo(group: string, messageId: string): Promise<IStatusResult> {
    return this.getJson<IStatusResult>('Msg/GroupReaded/-1', { group, msgid: messageId });
  }

  /**
   * potwierdzenie przeczytania wiadomości
   *
   * @param {number} index
   * @param {string} msgf
   * @param {string} to
   * @returns {Promise<IStatusResult>}
   * @memberof MailService
   */
  confirm(index: number, msgf: string, to: string): Promise<IStatusResult> {
    return this.getJson<IStatusResult>('Msg/Confirm/' + index, { msgf, to });
  }

  /**
   * Usunięcie wiadomości
   *
   * @param {number} index
   * @param {string} msgf
   * @returns {Promise<IStatusResult>}
   * @memberof MailService
   */
  delete(index: number, msgf: string): Promise<IStatusResult> {
    return this.getJson<IStatusResult>('Msg/Delete/' + index, { msgf });
  }
  /**
   * Wykonanie akcji zmian na wiadomości
   *
   * @param {MessageChangeOptions} options
   * @returns {Promise<MessageChangeResponse>}
   * @memberof MailService
   */
  async change(options: MessageChangeOptions): Promise<MessageChangeResponse> {
    let action = options.action.toString();
    if (options.action === MessageChangeAction.COLOR || options.action === MessageChangeAction.MOVE) {
      action += options.actionValue;
    }
    const data: any = { sa: action, id: options.index };
    if (options.msgf) {
      data.msgf = options.msgf;
    }
    const response = await this.getJson<any>('Msg/Change', data);
    if (!response.msgitem) {
      throw new Error('Nie można wykonać akcji.');
    }
    const mail = new Mail(response.msgitem);
    const responseFolders = response.fl || response.f;
    const folders = responseFolders ? MailFolder.fromList(responseFolders) : undefined;
    return { mail, folders };
  }

  /**
   * Zmiana wielu wiadomości
   *
   * @param {MessageChangeOptions} options
   * @param {number} [folder]
   * @returns {Promise<MessageChangeMultipleResponse>}
   * @memberof MailService
   */
  async changeMultiple(
    options: MessageChangeOptions,
    folder: number,
    listOptions?: MailListOptions
  ): Promise<MessageChangeMultipleResponse> {
    let action = options.action.toString();
    if (options.action === MessageChangeAction.COLOR || options.action === MessageChangeAction.MOVE) {
      action += options.actionValue;
    }

    const { url, reqobject } = this.prepareLoadParams(folder, listOptions);

    const data: any = { sa: action, id: options.index, ...reqobject };

    const changeActionUrl = folder || folder === 0 ? url : 'Msg/Change';
    const response = await this.getJson<any>(changeActionUrl, data);
    const mails = Mail.fromList(response.l);
    const responseFolders = response.fl || response.f;
    const folders = responseFolders ? MailFolder.fromList(responseFolders) : undefined;
    return { mails, folders };
  }

  /**
   * Przenieś wiadomość
   *
   * @param {number} index
   * @param {string} msgf
   * @param {number} folder
   * @returns {Promise<MessageChangeResponse>}
   * @memberof MailService
   */
  move(index: number, msgf: string, folder: number): Promise<MessageChangeResponse> {
    return this.change({ index, msgf, action: MessageChangeAction.MOVE, actionValue: folder.toString() });
  }

  /**
   * zwraca model nowo tworzonej wiadomości
   *
   * @returns {Promise<MailContent>}
   * @memberof MailService
   */
  create(): Promise<MailContent> {
    return this.getJson('Msg/Create').then(res => new MailContent(res));
  }
  /**
   * zwraca model wiadomości przekazywanej dalej
   *
   * @returns {Promise<MailContent>}
   * @memberof MailService
   */
  forward(index: number, msgf: string): Promise<MailContent> {
    return this.getJson('Msg/Forward/' + index, { msgf, skipCid: true }).then(res => new MailContent(res));
  }
  reply(index: number, msgf: string): Promise<MailContent> {
    return this.getJson('Msg/Reply/' + index, { msgf, skipCid: true }).then(res => new MailContent(res));
  }
  replyAll(index: number, msgf: string): Promise<MailContent> {
    return this.getJson('Msg/ReplyAll/' + index, { msgf, skipCid: true }).then(res => new MailContent(res));
  }
  send(msgf: string, msgData: MailEditModel): Promise<IStatusResult & { msgitem?: Mail }> {
    return this.postJson<IStatusResult & { msgitem?: any }>('Msg/Send', msgData, { queryData: { msgf } }).then(res => {
      if (res.msgitem) {
        res.msgitem = new Mail(res.msgitem);
      }
      return res;
    });
  }
  uploadAttachment(masgf: string, file: any, options: any) {}

  /**
   * pobieranie informacji o winmail
   * @param index index wiadomości
   * @param msgf nazwa pliku wiadomości
   * @returns
   */
  winmailInfo(index: number, msgf: string, attIndex: number = -1): Promise<MailWinmail> {
    return this.getJson<any>('Att/WinmailInfo/' + index, { msgf, index: attIndex }).then(res => new MailWinmail(res));
  }

  /**
   * url do pobrania załacznika z winmail
   * @param index index wiadomości
   * @param msgf nazwa pliku wiadomości
   * @param attIndex index załącznika w winmail.dat
   * @returns
   */

  winmailAttUrl(index: number, msgf: string, attIndex: number): string {
    return this.url(`Att/WinmailAtt/${index}`, { msgf, attIndex });
  }
  winmailRtfUrl(index: number, msgf: string, fileName: string = 'body.rtf'): string {
    return this.url(`Att/WinmailRtf/${index}`, { msgf, fileName });
  }

  /**
   * Przygotwuje dane do zapytania o listę wiadomości
   *
   * @private
   * @param {number} folder
   * @param {MailListOptions} [options]
   * @returns {{ url: string; reqparams: string[] }}
   * @memberof MailService
   */
  private prepareLoadParams(
    folder: number,
    options?: MailListOptions
  ): { url: string; reqparams: string[]; reqobject: any } {
    const reqparams: string[] = [];
    const reqobject: any = {};

    let url: string = `Msg/Box/${folder}?`;
    if (options && options.showOld === false) {
      reqparams.push('shold=0');
      reqobject.shold = 0;
    } else {
      reqparams.push('shold=1');
      reqobject.shold = 1;
    }
    if (options) {
      if (options.total !== undefined) {
        reqparams.push('total=' + options.total);
        reqobject.total = options.total;
      }
      if (options.folderInfo) {
        reqparams.push('finfo=1');
        reqobject.finfo = 1;
      }
      if (options.prevId) {
        reqparams.push('s=' + -options.prevId);
        reqobject.s = -options.prevId;
      } else if (options.start) {
        reqparams.push('s=' + options.start);
        reqobject.s = options.start;
      }
      if (options.count) {
        reqparams.push('ps=' + options.count);
        reqobject.ps = options.count;
      }
      if (options.sst) {
        reqparams.push('sst=' + encodeURIComponent(options.sst));
        reqobject.sst = options.sst;
        /*
        var sobj = MailSearchOptions.fromString(options.sst);
        let pf = (field: 'from' | 'to' | 'subject' | 'body' | 'attachment' | 'date' | 'size', qfield?: string) => {
          if (sobj[field]?.hasValue()) {
            reqparams.push((qfield || field) + '=' + encodeURIComponent((sobj[field] as any).toString()));
            reqobject[qfield || field] = (sobj[field] as any).toString();
          }
  
        }
        pf('from');
        pf('to');
        pf('subject');
        pf('size');
        pf('body');
        pf('attachment', 'att');
        pf('date');
        */
        url = `Msg/FullSearch/${folder}?`;
      } else if (options.query) {
        reqparams.push('q=' + encodeURIComponent(options.query));
        reqobject.q = options.query;
        url = `Msg/Search/${folder}?`;
      }
    }

    return { url, reqparams, reqobject };
  }
}

export const mailService = new MailService();
export default mailService;

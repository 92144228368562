<template>
  <header class="app-header">
    <dropdown :is-open="notReadDropdownActive" @click-outside="notReadDropdownActive = false">
      <template #target>
        <div class="app-header__toolbar">
          <date-strap>
            <nav v-if="numOfNotReadMails" class="text-right mr-3"
              @click="notReadDropdownActive = !notReadDropdownActive">
              <p class="text-right mr-3">
                Nieprzeczytanych <br />
                wiadomości
              </p>

              <p class="font-h2 weight-bold color-primary">{{ numOfNotReadMails }}</p>

              <w-icon mdi icon="menu-down" class="dropdown-icon"
                :class="{ 'dropdown-icon--visible': notReadDropdownActive }" />
            </nav>
          </date-strap>
          <slot />
        </div>
      </template>
      <h4>Nieprzeczytanych wiadomości: {{ numOfNotReadMails }}</h4>
      <folder-item v-for="folder in foldersWithNotRead" :key="folder.index" no-children :folder="folder"
        :currentFolder="currentFolder" :folders="foldersWithNotRead" />
    </dropdown>

    <div v-if="isLoggedIn" class="app-header__navigation">
      <div class="app-header__app-menu">
        <button class="btn btn--flat" @click="showAppMenu = true">
          <w-icon mdi icon="apps" />

          <p class="mx-2">{{ activeMenu?.name }}</p>

          <w-icon mdi icon="menu-down" class="dropdown-icon" :class="{ 'dropdown-icon--visible': showAppMenu }" />
        </button>

        <dropdown :is-open="showAppMenu" @click-outside="showAppMenu = false">
          <nav>
            <div v-for="(menuItem, index) in menu" :key="'menu_item_' + index" class="nav-item">
              <router-link class="btn" :to="menuItem.route">
                <w-icon mdi :icon="menuItem.icon" />

                {{ menuItem.name }}
              </router-link>
            </div>
          </nav>
        </dropdown>
      </div>

      <div class="app-header__profile-menu">
        <button class="btn btn--flat" @click="showProfileMenu = true">
          <p v-if="currentUser" class="mx-2">{{ currentUser.Account }}</p>

          <w-icon mdi icon="menu-down" class="dropdown-icon" :class="{ 'dropdown-icon--visible': showProfileMenu }" />
        </button>

        <dropdown block :is-open="showProfileMenu" @click-outside="showProfileMenu = false">
          <nav>
            <div class="nav-item">
              <button v-for="user in usersList" :key="user.UserID" class="btn" @click="switchUser(user)">{{ user.Account
              }}</button>

              <button class="btn" @click="loginModalActive = true">Dodaj konto</button>

              <button v-if="usersList.length > 0" class="btn" @click="logout">wyloguj</button>

              <router-link to="/logout" class="btn">
                {{ usersList.length === 0 ? 'wyloguj' : 'wyloguj wszystkie' }}
              </router-link>
            </div>
          </nav>
        </dropdown>
      </div>
    </div>

    <login-modal :active="loginModalActive" @close="loginModalActive = false" @login="login" />

  </header>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ILoginResult } from '@infocity/mail-common/interfaces/loginResult';

import DateStrap from '@/components/DateStrap.vue';
import Dropdown from '@/components/helpers/Dropdown.vue';
import FolderItem from '@/views/Mail/Box/FolderItem.vue';
import LoginModal from '@/components/modals/LoginModal.vue';
import mailStore from '@/store/mail';
import { isLoggedIn } from '@/store';
import { list, load } from '@/store/mailFolder';
import { activeUsers, changeUser, currentUser, removeUser } from '@/store/users';

/**
 * Górna belka poczty z nawigacją
 */
export default defineComponent({
  components: { Dropdown, DateStrap, FolderItem, LoginModal },

  setup() {
    const showAppMenu = ref(false);
    const showProfileMenu = ref(false);

    const currentRoute = useRoute();
    const router = useRouter();

    const menu = [
      { name: 'Dziś', icon: 'clock', route: { name: 'today' } },
      { name: 'Poczta', icon: 'email', route: { name: 'box-root' } },
      { name: 'Kontakty', icon: 'account-box', route: { name: 'contact' } },
      { name: 'Terimnarz', icon: 'calendar', route: { path: 'calendar' } },
      { name: 'Notatnik', icon: 'notebook', route: { path: 'notebook' } },
      { name: 'Moje pliki', icon: 'file-multiple', route: { path: 'file-manager' } },
      { name: 'Ustawienia', icon: 'cog', route: { name: 'settings' } }
    ];

    const activeMenu = computed(() =>
      menu.find(
        menuItem => menuItem.route.name && currentRoute.matched.some(route => route.name === menuItem.route.name)
      )
    );

    const loginModalActive = ref(false);

    const usersList = computed(() => activeUsers.value.filter(user => user.UserID !== currentUser.value?.UserID));

    /**
     * Zmienia użytkownika
     * 
     * @param {ILoginResult |  null} user użytkownik którego konto ma być użyte
    */
    function switchUser(user: ILoginResult | null): Promise<void> {
      if (user) {
        return changeUser(user).then(redirect);
      }
      return router.push({ name: 'logut' }).then(undefined);
    }

    /**
     * Wylogowuje obecnie zalogowanego użytkowika
    */
    function logout(): Promise<void> {
      removeUser(currentUser.value);
      return switchUser(usersList.value[usersList.value.length - 1]);
    }

    /**
     * Przekierowuje do today po zmianie konta
    */
    function redirect(): Promise<void> {
      return router.push({ name: 'today' }).then(() => { mailStore.load(-1, {}) });
    }

    /**
     * Przekierowuje 
    */
    function login(): Promise<void> {
      loginModalActive.value = false;
      return redirect();
    }

    const foldersWithNotRead = computed(() => list.value.filter(folder => folder.unread));

    const numOfNotReadMails = computed(() =>
      foldersWithNotRead.value.map(folder => folder.unread).reduce((left, right) => left + right, 0)
    );

    const notReadDropdownActive = ref(false);

    const currentFolder = computed(() => parseInt(currentRoute.params.folder?.toString()));

    onMounted(() => (list.value.length ? null : load()));

    return {
      activeMenu,
      isLoggedIn,
      menu,
      showAppMenu,
      showProfileMenu,
      currentUser,
      loginModalActive,
      usersList,
      activeUsers,
      numOfNotReadMails,
      foldersWithNotRead,
      notReadDropdownActive,
      currentFolder,
      currentRoute,

      redirect,
      changeUser,
      removeUser,
      logout,
      switchUser,
      login

    }
  }
});
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.app-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px map-get($map: $theme-colors, $key: border);
  height: map-get($map: $theme-sizes, $key: header);

  .app-header__navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px;
  }

  .app-header__toolbar {
    display: flex;
    flex: 1;
    align-items: center;
    margin-right: 40px;
  }

  .app-header__app-menu,
  .app-header__profile-menu {
    position: relative;

    .dropdown {
      left: unset;
      right: 0;
      max-width: 250px;
    }
  }

  .app-header__app-menu {
    margin-right: 24px;

    .nav-item {
      width: 50%;
      padding: 8px;

      .btn {
        flex-direction: column;
        height: auto;

        i {
          margin-right: 0;
          color: map-get($map: $theme-colors, $key: text);
        }
      }

      .active,
      .router-link-active {
        color: map-get($map: $theme-colors, $key: primary);

        i {
          color: map-get($map: $theme-colors, $key: primary);
        }
      }
    }
  }

  .app-header__profile-menu {
    .nav-item {
      width: 100%;
      padding: 8px;

      .btn {
        flex-direction: column;
        height: auto;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        i {
          color: map-get($map: $theme-colors, $key: text);
        }
      }

      .active,
      .router-link-active {
        color: map-get($map: $theme-colors, $key: primary);

        i {
          color: map-get($map: $theme-colors, $key: primary);
        }
      }
    }
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.app-logo {
  width: map-get($map: $theme-width, $key: aside);
}
</style>

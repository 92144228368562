import { CardDetails } from '@infocity/mail-common/interfaces/cardDetails';
import { IStatusResult } from '@infocity/mail-common/interfaces/statusResult';
import { camelCaseObject } from '@infocity/mail-common/methods/camelCaseObject';
import { Card } from '@infocity/mail-common/models/card';
import { BaseService } from '@infocity/mail-common/services/base.service';
import { ServiceSettings } from '@infocity/mail-common/services/serviceSettings';

export class CardService extends BaseService {
  constructor(settings?: ServiceSettings) {
    super(settings);
  }
  /**
   * pobierz wszystkie kontakty z książki
   *
   * @return {*}  {Promise<Card[]>}
   * @memberof CardService
   */
  list(): Promise<Card[]> {
    return this.getJson<any[][]>('Card/All').then(res => Card.fromList(res));
  }

  /**
   * Pobierz dane kontaktu
   *
   * @param {number} id
   * @return  {Promise<CardDetails>}
   * @memberof CardService
   */
  get(id: number): Promise<CardDetails> {
    return this.getJson<CardDetails>('Card/Get', { id }).then(contact => camelCaseObject<CardDetails>(contact));
  }

  /**
   * Zapis zmian w kontakcie / tworzenie nowego (dla card.id == 0)
   * @param card dane kontaktu
   * @param name nazwa folderu
   * @param parent folder nadrzędny
   */
  save(card: CardDetails): Promise<IStatusResult & { Item?: CardDetails }> {
    return this.postJson<any>('Card/Save', card).then(res => {
      if (res.Status === 'Ok') {

      }
      if (res.Item) {
        res.Item = camelCaseObject<CardDetails>(res.Item)
      }
      return res;
    });
  }

  /**
   * Usunięcie kontaku
   * @param id id kontaktu
   */
  remove(id: number): Promise<IStatusResult> {
    return this.postJson<any>('Card/Delete', { id });
  }

  /**
   * informacje o nadawcy, czy jest na white i black list, albo w książce adresowej
   *
   * @param {string} mail
   * @returns {Promise<any>}
   * @memberof CardService
   */
  senderInfo(
    mail: string
  ): Promise<
    IStatusResult & { IsBlacklisted: boolean; IsAutoWhitelisted: boolean; IsWhitelisted: boolean; Card: any[] }
  > {
    return this.getJson('Card/SenderInfo', { mail });
  }




}

export const cardService = new CardService();
export default cardService;

import { computed, ComputedRef, Ref, ref } from 'vue';

import { Orientation } from '@/interfaces/orientation';
import { ElementMargins, ElementRectangle } from '@/interfaces/rect';
import { UsePositionOptions } from '@/interfaces/usePositionOptions';

const defaultOptions: UsePositionOptions = {
  target: ref(),
  targetMargin: 80,
  defaultYPosition: 'bottom',
  defaultXPosition: 'left',
  orientationClassPrefix: 'w-position--'
};

/**
 * Wyliczanie pozycji elementu (toolitp,dropdown) w zależności od wolnego miejsca
 *
 * @export
 * @param {Partial<UsePositionOptions>} [options]
 * @return {({
 *   target: Ref<Element | undefined>;
 *   position: Ref<string>;
 *   orientation: Ref<Orientation>;
 *   orientationClass: ComputedRef<{ [className: string]: boolean }>;
 *   setPosition: () => void;
 * })}
 */
export function usePosition(options?: Partial<UsePositionOptions>): {
  target: Ref<Element | undefined>;
  position: Ref<string>;
  orientation: Ref<Orientation>;
  orientationClass: ComputedRef<{ [className: string]: boolean }>;
  setPosition: () => void;
} {
  const { targetMargin, defaultXPosition, defaultYPosition, orientationClassPrefix } = {
    ...defaultOptions,
    ...options
  };

  /**
   * Element HTML, do którego liczona będzie pozycja
   */
  const target = ref<Element>();

  /**
   * Docelowy styl pozycji
   */
  const position = ref('');

  /**
   * Wyliczona orientacja
   */
  const orientation = ref<Orientation>({ x: 'center', y: defaultYPosition });

  /**
   * Wstawia klasę odpowiedzialną za orientację dropdowna
   */
  const orientationClass = computed(() => ({
    [orientationClassPrefix + orientation.value.y]: true,
    [orientationClassPrefix + orientation.value.x]: true
  }));

  /**
   * Pozycja X tooltpia względem całego dokumentu
   *
   * @param {ElementRectangle} targetRect
   * @param {ElementMargins} targetSpace
   * @param {('center' | 'left' | 'right')} position
   * @return {string}
   */
  function calculate3dTX(
    targetRect: ElementRectangle,
    targetSpace: ElementMargins,
    position: 'center' | 'left' | 'right'
  ): string {
    if (position === 'center') {
      return targetSpace.left + window.scrollX + targetRect.width / 2 + 'px';
    }
    if (position === 'left') {
      return targetSpace.left + window.scrollX + 'px';
    }
    // right
    return targetSpace.left + targetRect.width + window.scrollX + 'px';
  }

  /**
   * Pozycja Y tooltpia względem całego dokumentu
   *
   * @param {ElementRectangle} targetRect
   * @param {ElementMargins} targetSpace
   * @param {('top' | 'bottom')} position
   * @return {string}
   */
  function calculate3dTY(
    targetRect: ElementRectangle,
    targetSpace: ElementMargins,
    position: 'top' | 'bottom'
  ): string {
    if (position === 'top') {
      return targetSpace.top + window.scrollY + 'px';
    }
    // bottom
    return targetSpace.top + targetRect.height + window.scrollY + 'px';
  }

  /**
   * Przesunięcie w osi X
   *
   * @param {('center' | 'left' | 'right')} position
   * @return {string}
   */
  function calculateTX(position: 'center' | 'left' | 'right'): string {
    if (position === 'center') {
      return '-50%';
    }
    if (position === 'left') {
      return '0';
    }
    // right
    return '-100%';
  }

  /**
   * Przesunięcie w osi Y
   *
   * @param {('top' | 'bottom')} position
   * @return {string}
   */
  function calcluateTY(position: 'top' | 'bottom'): string {
    if (position === 'top') {
      return '-100%';
    }
    // bottom
    return '0';
  }

  /**
   * Przypisuje pozycję targetu oraz jego wolną przestrzeń na viewporcie
   *
   * @return {{ targetRect: ElementRectangle; targetSpace: ElementMargins }}
   */
  function getTargetValues(): { targetRect: ElementRectangle; targetSpace: ElementMargins } {
    if (!target.value) {
      return {
        targetRect: { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0 },
        targetSpace: { bottom: 0, left: 0, right: 0, top: 0 }
      };
    }
    const targetRect: ElementRectangle = target.value?.getBoundingClientRect();
    const targetSpace: ElementMargins = {
      left: targetRect.left,
      right: window.innerWidth - targetRect.left - targetRect.width,
      top: targetRect.top,
      bottom: window.innerHeight - targetRect.top - targetRect.height
    };

    return { targetRect, targetSpace };
  }

  /**
   * Sprawdza jaką orientację powinien mieć tooltip
   *
   * @param {ElementMargins} targetSpace
   * @return {Orientation}
   */
  function getOrientation(targetSpace: ElementMargins): Orientation {
    const orientation: Orientation = { y: defaultYPosition, x: defaultXPosition };

    if (defaultYPosition === 'top' && targetSpace.top < targetMargin) {
      orientation.y = 'bottom';
    } else if (defaultYPosition === 'bottom' && targetSpace.bottom < targetMargin) {
      orientation.y = 'top';
    }

    if (targetSpace.left < targetMargin) {
      orientation.x = 'left';
    } else if (targetSpace.right < targetMargin) {
      orientation.x = 'right';
    }

    return orientation;
  }

  /**
   * Wylicza pozycję
   *
   */
  function setPosition(): void {
    const { targetRect, targetSpace } = getTargetValues();
    orientation.value = getOrientation(targetSpace);

    const translate3dTX = calculate3dTX(targetRect, targetSpace, orientation.value.x);
    const translate3dTY = calculate3dTY(targetRect, targetSpace, orientation.value.y);
    const translateTX = calculateTX(orientation.value.x);
    const trasnlateTY = calcluateTY(orientation.value.y);

    position.value =
      'transform:' +
      'translate3d(' +
      translate3dTX +
      ',' +
      translate3dTY +
      ',' +
      '0)' +
      'translate(' +
      translateTX +
      ',' +
      trasnlateTY +
      ')';
  }

  return {
    orientationClass,
    orientation,
    position,
    target,

    setPosition
  };
}

<template></template>

<script lang="ts">
import { cookieGet } from '@infocity/mail-common/methods/cookies';
import { defineComponent } from 'vue';

import { useRouter, goLogin, goHome } from '../router/router';
import { accountService } from '../services/account.service';
import store from '../store';

export default defineComponent({
  setup() {
    const router = useRouter();
    var sid = cookieGet('sid');
    var uid = cookieGet('uid');
    if (sid && uid) {
      accountService.checkSession(uid, sid).then(res => {
        if (res.Status === 'Ok') {
          store.setUser(res);
          return goHome();
        } else {
          goLogin();
        }
      });
    } else {
      goLogin();
    }
  }
});
</script>

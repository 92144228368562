<template>
  <div role="alert" class="toast" :class="classess" @mouseenter.self="pause" @mouseleave.self="resume">
    <w-button ghost sm icon="close" label="buttons.close" class="toast__close-button" @click.prevent="clear" />

    <div class="toast__content">
      <component :is="toast.component" :toast="toast" v-bind="toast.props" />
<!--
      <div v-if="toast.expirationTimestamp" class="toast__progress-bar" :style="{ width: progress + '%' }" />
      -->
      <!--
      <div v-if="toast.expirationTimestamp" class="toast__progress-bar" :style="progressStyles" />
      -->
      <div>{{}}</div>
      <div v-if="toast.miliseconds" class="toast__progress-bar" :style="progressStyles" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';

import { Toast  } from '../../interfaces/toast';
import { toastService } from '../../services/toast.service';

/**
 * Powiadomienie
 */
export default defineComponent({
  props: {
    /**
     * Powiadomienie
     */
    toast: { type: Object as PropType<Toast>, required: true }
  },
  setup(props) {
    const classess = computed(() => `${props.toast.cssClassess || ''}`);


    const progressStyles = computed(() => {
        return {
            animation: `progressToast ${props.toast.miliseconds}ms linear`,
            animationPlayState: props.toast.pauseTimestamp ? "paused" : "running"
        }
    })
    /**
     * Usuwa notyfikację
     */
    function clear(): void {
      toastService.clear(props.toast);
    }

    /**
     * Pauzuje wygasanie notyfikacji
     */
    function pause(): void {
      toastService.pause(props.toast);
    }

    /**
     * Wznawia wygasanie notyfikacji
     */
    function resume(): void {
      toastService.resume(props.toast);
    }

    return {
      classess,
      progressStyles,

      clear,
      pause,
      resume
    };
  }
});
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.toast {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: size('toast-w');
  margin-bottom: 12px;
  background: color('white');
  border-radius: radius('small');
  position: relative;
  -webkit-box-shadow: 16px 16px 24px -12px rgba(40, 40, 47, 0.4);
  -moz-box-shadow: 16px 16px 24px -12px rgba(40, 40, 47, 0.4);
  box-shadow: 16px 16px 24px -12px rgba(40, 40, 47, 0.4);
  transition: transition('fast');
  border: solid 2px color('border');
  overflow: hidden;

  animation-duration: 0.3s;
  animation-name: showToast;

  .toast__close-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .toast__progress-bar {
    display: block;
    height: 4px;
    background: color('border');
  }

  .toast-message {
    display: flex;
    justify-content: space-between;
    padding: 12px;
  }

  &:hover {
    box-shadow: none;
    transform: translateX(4px);
  }
}

@keyframes progressToast {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
    
}

@keyframes showToast {
  0% {
    transform: scale(0.9) translateY(-40px);
    opacity: 0;
  }

  80% {
    transform: scale(1.02) translateX(0);
    opacity: 1;
  }

  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}
</style>

import { IStatusResult } from '@infocity/mail-common/interfaces/statusResult';
import { CardGroup } from '@infocity/mail-common/models/cardGroup';
import { BaseService } from '@infocity/mail-common/services/base.service';
import { ServiceSettings } from '@infocity/mail-common/services/serviceSettings';
import { AbstractService } from '../interfaces/abstractService';
import { camelCaseObject } from '@infocity/mail-common/methods/camelCaseObject';

/**
 * Serwis do edycji, dodawania i pobierania grup.
 *
 * @export
 * @class GroupService
 * @extends {BaseService}
 */
export class CardGroupService extends BaseService implements AbstractService<number, CardGroup, CardGroup> {
  constructor(settings?: ServiceSettings) {
    super(settings);
  }

  /**
   * Pobranie pojedynczej grupy
   *
   * @param {number} id
   * @return {Promise<CardGroup>}
   * @memberof CardGroupService
   */
  get(id: number): Promise<CardGroup> {
    return this.getJson<any>(`CardGroup/Get/${id}`).then(res => new CardGroup([res.Id, res.Name]));
  }

  /**
   * Pobranie wszystkich grup użytkownika
   *
   * @return {*}  {Promise<CardGroup[]>}
   * @memberof CardService
   */
  list(): Promise<CardGroup[]> {
    return this.getJson<any[][]>('CardGroup/index').then(res => CardGroup.fromList(res));
  }

  /**
   * Tworzenie grupy
   *
   * @param {string} name
   * @return {*}  { Promise<IStatusResult & { Item?: CardGroup }
   * @memberof GroupService
   */
  create(model: CardGroup): Promise<IStatusResult & { Item?: CardGroup }> {
    return this.postJson<any>('CardGroup/save', { name: model.name, label: model.label }).then(res => {
      if (res.Item) {
        res.Item = camelCaseObject<CardGroup>(res.Item)
      }
      return res;
    });;
  }

  /**
   * zapisz grupę
   *
   * @param {number} id
   * @return {*}  {Promise<IStatusResult>}
   * @memberof GroupService
   */
  update(model: CardGroup): Promise<IStatusResult> {
    return this.postJson('CardGroup/save', { Id: model.id, Name: model.name });
  }

  /**
   * Usuń grupę
   *
   * @param {number} id
   * @return {*}  {Promise<IStatusResult>}
   * @memberof GroupService
   */
  delete(id: number): Promise<IStatusResult> {
    return this.postJson('CardGroup/delete', { id });
  }
}

export const cardGroupService = new CardGroupService();
export default cardGroupService;

<template>
  <modal class="login-modal" :active="active" @close="$emit('close')">
    <div class="login-container-content">
      <h1>Zaloguj się</h1>

      <form method="post" @submit.prevent="login">
        <label for="login-email"> Adres email</label>

        <input id="login-email" v-model="email" name="user" class="input w-100 mb-4" />

        <label for="login-password"> Hasło</label>

        <input id="login-password" v-model="password" name="password" type="password" class="input w-100 mb-4" />

        <button-cta outlined type="submit" text="Zaloguj się" class="w-100 mt-4" />
      </form>
    </div>
  </modal>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";

import Modal from '@/components/interface/Modal.vue';
import { useLogin } from '@/composables/login';

/**
 * Modal logownia
*/
export default defineComponent({
  components: { Modal },
  props: {
    /**
     * Czy modal jest aktywny
    */
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    /**
     * Zamknięcie modal
    */
    close: null,
    /**
     * Zalogownie użytkownika
    */
    login: null
  },
  setup(props, { emit }) {

    const { email, password, login } = useLogin(() => { emit('login'); return Promise.resolve() });

    watch(() => props.active, newValue => {
      if (newValue) {
        email.value = '';
        password.value = '';
      }
    });

    return {
      email,
      password,

      login
    }
  }
})
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.login-modal {
  .login-container-content {
    padding: 120px 60px 0 60px;

    @media screen and (max-width: map-get($grid-breakpoints, md)) {
      padding: 80px 32px 0 32px;
    }

    @media screen and (max-width: map-get($grid-breakpoints, md)) {
      padding: 40px 16px 0 16px;
    }
  }
}
</style>

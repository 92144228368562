import { cookieGet } from '@infocity/mail-common/methods/cookies';
import { RouteLocationNormalized } from 'vue-router';

import { accountService } from '../services/account.service';
import store from '../store';

/**
 * Sprawdzenie czy użytkownik jest zalogowany, przekierowanie na logowanie
 *
 * @param {RouteLocationNormalized} to
 * @returns
 */
export async function checkLogged(to: RouteLocationNormalized): Promise<any> {
  if (!store.isLoggedIn.value) {
    // próbuj mimo wszystko
    var sid = cookieGet('sid');
    var uid = cookieGet('uid');
    if (sid && uid) {
      try {
        const res = await accountService.checkSession(uid, sid);
        if (res.Status === 'Ok') {
          store.setUser(res);
          return;
        }
      } catch (err) {
        console.warn('err checkLogged', err);
      }
    }
    return { path: '/login', query: { path: to.fullPath } };
  }
}

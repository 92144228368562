import { createRouter, createWebHashHistory } from 'vue-router';

import Start from '../views/Start.vue';
import { boxRoute } from './main/box';
import { todayRoute } from './main/box/today.route';
import { contactRoute } from './main/contacts/contacts.route';
import { settingsRoute } from './main/settings/settings.route';

export const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    todayRoute,
    boxRoute,
    settingsRoute,
    contactRoute,
    { path: '/', component: Start },
    { path: '/login', name: 'login', component: () => import('../views/Login.vue') },
    { path: '/logout', name: 'logout', component: () => import('../views/Logout.vue') }
  ]
});

export function goLogin() {
  router.push({ name: 'login' });
}
export function goHome() {
  router.push('/box');
}

export function useRouter() {
  return router;
}

export default router;

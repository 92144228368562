import { generateGuid } from '@witkac/jschema/lib/schemaMethods';
import { markRaw, ref, Ref } from 'vue';

import { Toast } from '../interfaces/toast';
import { ToastOptions } from '../interfaces/toastOptions';
// import { DEFAULT_NOTIFICATION_TIMEOUT } from '../utils/constants';


export const DEFAULT_NOTIFICATION_TIMEOUT = 30000;

/**
 * Powiadomienia
 *
 * @export
 * @class ToastService
 */
export class ToastService {
    /**
     * Lista powiadomień
     *
     * @type {Ref<Toast[]>}
     * @memberof ToastService
     */
    public toasts: Ref<Toast[]> = ref([]);

    /**
     * Utworzenie powiadomienia
     *
     * @param {ToastOptions} [options={}]
     * @memberof ToastService
     */
    public create(options: ToastOptions = {}): void {
        const toast: Toast = {
            cssClassess: options.cssClassess,
            props: options.props,
            id: generateGuid()
        };
        const miliseconds = this.timeoutMiliseconds(options);
        if (miliseconds) {
            const expirationTimestamp = Date.now() + miliseconds;
            const timeoutId = this.prepareClearTimeout(miliseconds);
            toast.miliseconds = miliseconds;
            toast.expirationTimestamp = expirationTimestamp;
            toast.timeoutId = timeoutId;
        }

        if (options.component) {
            toast.component = markRaw(options.component);
        }

        this.toasts.value.push(toast);
    }

    /**
     * Usuwa powiadomienie
     *
     * @param {Toast} toast
     * @memberof ToastService
     */
    public clear(toast: Toast): void {
        clearTimeout(toast.timeoutId);
        this.toasts.value = this.toasts.value.filter(listToast => listToast.id !== toast.id);
    }

    /**
     * Pauzuje wygasanie notyfikacji
     *
     * @param {Toast} toast
     * @memberof ToastService
     */
    public pause(toast: Toast): void {
        if (!toast.expirationTimestamp) {
            return;
        }
        clearTimeout(toast.timeoutId);
        toast.pauseTimestamp = Date.now();
    }

    /**
     * Wznawia wygasanie notyfikacji
     *
     * @param {Toast} toast
     * @memberof ToastService
     */
    public resume(toast: Toast): void {
        if (!toast.pauseTimestamp || !toast.expirationTimestamp) {
            return;
        }
        const milisecondsLeft = toast.expirationTimestamp - toast.pauseTimestamp;
        toast.expirationTimestamp = Date.now() + milisecondsLeft;
        toast.timeoutId = this.prepareClearTimeout(milisecondsLeft);
        toast.pauseTimestamp = undefined;
    }

    /**
     * Przygotowuje timeout
     *
     * @private
     * @param {number} miliseconds
     * @return {number}
     * @memberof ToastService
     */
    private prepareClearTimeout(miliseconds: number): number {
        return setTimeout(
            () =>
            (this.toasts.value = this.toasts.value.filter(
                toast => !!toast.pauseTimestamp || !toast.expirationTimestamp || toast.expirationTimestamp > Date.now()
            )),
            miliseconds
        ) as any as number; // TS próbuje wmówić, że setTimeout zwraca NodeJS.Timeout
    }

    /**
     * Zwraca czas życia powiadomienia w milisekundach
     *
     * @private
     * @param {ToastOptions} options
     * @return {(number | undefined)}
     * @memberof ToastService
     */
    private timeoutMiliseconds(options: ToastOptions): number | undefined {
        if (options.miliseconds === undefined) {
            options.miliseconds = DEFAULT_NOTIFICATION_TIMEOUT;
        }

        if (options.miliseconds === null || options.miliseconds <= 0) {
            return;
        }

        return options.miliseconds;
    }
}

export const toastService = new ToastService();
